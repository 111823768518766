<template>
    <div class="appendDialog">

        <el-dialog :title="myTitle" width="750px"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-tabs v-model="activeName" @tab-click="tabClick">
                <el-tab-pane label="数据录入" name="a">
                    <el-form :inline="true" :model='appendForm' :rules="appendFormRules" ref="appendForm" @submit.native.prevent class="demo-form-inline search-form">

                        <el-form-item label="行政班" prop="hardId">
                            <el-select v-model="appendForm.hardId"  filterable placeholder="请选择" @change="hardChange">
                                <el-option v-for="item in hardClassList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="学生" prop="studentId">
                            <el-select v-model="appendForm.studentId" filterable placeholder="请选择" @change="studentChange">
                                <el-option v-for="item in studentList" :key="item.id" :label="item.nick_name+'('+item.my_no+')'" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="总分" prop="score">
                            <el-input v-model="appendForm.score" placeholder="分数" style="width: 100px;"></el-input>
                        </el-form-item>

                    </el-form>
                    <el-table v-loading="loading" :data="appendData" style="width: 100%" height="400" align='center'>

                        <el-table-column prop="topic_index" label="序号" min-width="30" align='center' ></el-table-column>
                        <el-table-column prop="topic_no" label="题号"  min-width="30" align='center' ></el-table-column>
                        <el-table-column prop="topic_type" label="题型"  min-width="50" align='center' :formatter="formatterTopicType"></el-table-column>
                        <el-table-column prop="score" label="满分"  min-width="50" align='center' ></el-table-column>
                        <el-table-column prop="anwScore" min-width="100" label="操作" align='center' >
                            <template slot-scope='{row}'>
                                <el-input-number v-model="row.anwScore" :min="0" :precision="1" :step="1" :max="row.score" @blur="calcScore" ></el-input-number>
                            </template>
                        </el-table-column>

                    </el-table>

                </el-tab-pane>
                <el-tab-pane label="已补录列表" name="z">
                    <el-table v-loading="loading" :data="tableData" style="width: 100%" height="400" align='center'>
                        <el-table-column prop="hard_name" label="班级" align='center' ></el-table-column>
                        <el-table-column prop="student_no" label="学号"   align='center' ></el-table-column>
                        <el-table-column prop="student_name" label="姓名"   align='center' ></el-table-column>
                        <el-table-column prop="score" label="总分" min-width="80" align='center'  ></el-table-column>
                        <el-table-column prop="score" min-width="80" label="操作" align='center' >
                            <template slot-scope='{row}'>
                                <el-button type="danger" icon='edit' size="mini" @click='onDelete(row)'>删除</el-button>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-tab-pane>

            </el-tabs>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
                <el-button type="primary" :loading="loading" v-if="activeName=='a'" @click="submitForm">添 加</el-button>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'
import XLSX from "xlsx";

    export default {
        name: 'appendDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '设置',
                dialogVisible:false, loading: false, activeName: 'a',
                appendData: [], tableData: [], columns: [], hardClassList: [], studentList: [],
                examId: null, examName: null,
                appendForm: { examId: null, hardId: null, studentId: null, score: null },
                appendFormRules: {
                    hardId: [{ required: true, message: '请选择班级', trigger: 'blur' }],
                    studentId: [{ required: true, message: '请选择学生', trigger: 'blur' }],
                    score: [{ required: true, message: '请录入总分', trigger: 'blur' }],
                },

            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            append: {
                type: Object
            }
        },
        watch: {
            append: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("append", n);
                    if(n){
                        this.examId = n.id;
                        this.appendForm.examId = n.id;
                        this.examName = n.name;
                        this.myTitle = n.id + ":" + n.name + "-补录数据"
                        this.loadHardClassIdList();
                    }
                }
            },
        },
        methods: {
            showDialog(){

                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            tabClick(){
                if(this.activeName == 'z'){
                    this.loadAppendList();
                }
            },

            loadHardClassIdList(){

                this.hardClassList = [];
                let search = { examId: this.examId, licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'exam/append/', methodName: '/hardList', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (code == 0) {
                        this.hardClassList = data;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },

            loadStudentList(hardId){
                this.studentList = [];
                let search = { examId: this.examId, hardId: hardId};
                let param = { controllerName: 'exam/append/', methodName: '/studentList', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (code == 0) {
                        this.studentList = data;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            hardChange(v){
                this.loadStudentList(v);
            },
            studentChange(v){
                this.loadTopicList();
            },
            calcScore(){

                let myScore = 0;
                for (let i=0; i<this.appendData.length; i++){
                    let a = this.appendData[i];
                    let anwScore = (("anwScore" in a)&& a.anwScore!=null)?parseFloat(a.anwScore):0;
                    myScore = myScore + anwScore;
                }
                this.appendForm.score = myScore;
            },
            loadTopicList(){
                if(this.examId == null) return ;
                this.kgIndexList = [];
                let search = { examId: this.examId, orderBy:  'topic_index'};
                let param = { controllerName: 'akExamTopic', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadTopicList", data);
                    if (data.code == 0) {
                        let list = data.result;
                        this.appendData = list;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadAppendList(){
                if(this.examId == null) return ;
                let search = { examId: this.examId, orderBy:  'student_no'};
                let param = { controllerName: 'exam/append/', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadAppendList", data);
                    if (code == 0) {
                        this.tableData = data;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            formatterTopicType(row, column, cellValue, index) {

                if(cellValue == 'Object') return '客观题';
                if(cellValue == 'SingleChoice') return '单选题';
                if(cellValue == 'MultiChoice') return '多选题';
                if(cellValue == 'Judge') return '判断题';
                if(cellValue == 'FillBlank') return '填空题';
                if(cellValue == 'Subjective') return '主观题';
                if(cellValue == 'AnswerQuestion') return '解答题';
                if(cellValue == 'Composition') return '语文作文题';
                if(cellValue == 'CNComposition') return '语文作文题';
                if(cellValue == 'EnglishComposition') return '英语作文题';
                return cellValue;
            },

            submitForm(){
                console.log("appendData", this.appendData);
                let that = this;
                this.$refs.appendForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.appendForm.examId = this.examId;
                        let f = XEUtils.find(this.studentList, item => {
                            return item.id == this.appendForm.studentId;
                        });
                        let scoreList = [];
                        for (let i=0; i<this.appendData.length; i++){
                            let a = this.appendData[i];
                            let anwScore = (("anwScore" in a)&& a.anwScore!=null)?parseFloat(a.anwScore):0;
                            scoreList.push({userScore: anwScore, num: a.topic_index, topic_no: a.topic_no, t1: a.topic_type, t2: a.topic_type2, rightScore: a.score});
                        }
                        let p = {examId:this.examId, score: parseFloat(this.appendForm.score), studentId: this.appendForm.studentId,
                            studentNo: f.my_no, studentName: f.nick_name,
                            answerText: JSON.stringify(scoreList)};

                        let param = { controllerName: 'exam/append/', methodName: 'saveOrUpdate', param: p};
                        console.log('append', param);
                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('append', code);
                            if (code == 0) {
                                this.appendForm.studentId = null;
                                this.appendForm.score = null;
                                this.appendData = [];
                            }else if(code == -3){
                                this.$message({ type: 'error', message: '该学生已部补录，请先更换其他学生！'});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
            onDelete(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该学生以及补录数据, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'exam/append/', methodName: 'delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadAppendList();
                        }

                    });
                }).catch(() => {

                });
            }

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .appendDialog{

    }


</style>


