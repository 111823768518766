<template>
    <div class="newExamTopicDialog">

        <el-dialog width="1300px"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <div slot="title">
                <span class="my-title">{{myTitle}}</span>
                <div v-if="examSheetForm.sheetId != null" style="padding-top: 10px;">
                    <span class="title-desc">答题卡：{{examSheetForm.sheetName}}({{examSheetForm.sheetId}})</span>
                </div>
            </div>

            <div>
                <span style="font-size: 14px;color: #4D4D4D;">本试卷共有{{newExamTopicForm.kgCount+newExamTopicForm.zgCount}}道题目:
                    客观题{{newExamTopicForm.kgCount}}道，共{{newExamTopicForm.kgScore}}分、
                    主观题{{newExamTopicForm.zgCount}}道，共{{newExamTopicForm.zgScore}}分，
                    总分值
                    <span style="color: red;">{{parseFloat(newExamTopicForm.kgScore)+parseFloat(newExamTopicForm.zgScore)}}</span>
                    分</span>
            </div>
            <el-tabs v-model="activeName" @tab-click="tabClick">
                <el-tab-pane label="客观题设置" name="kg">
                    <el-form :inline="true" :model='kgForm' :rules="kgFormRules" ref="kgForm" @submit.native.prevent class="demo-form-inline search-form">
                        <el-form-item label="起始序号"  prop="startIndex">
                            <el-select v-model="kgForm.startIndex" filterable placeholder="请选择" style="width: 100px;">
                                <el-option v-for="item in kgIndexList" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="结束序号" prop="endIndex">
                            <el-select v-model="kgForm.endIndex" filterable placeholder="请选择" style="width: 100px;">
                                <el-option v-for="item in kgIndexList" :key="item" :label="item" :value="item"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="每题分数" prop="score">
                            <el-input v-model="kgForm.score" placeholder="分数" style="width: 100px;"></el-input>
                        </el-form-item>
                        <el-form-item :label="isAb?'A卷答案':'答案(非必填)'">
                            <el-tooltip class="item" effect="dark" content="格式：A,B,AB,或T,T,F,F，每题英文逗号隔开" placement="top">
                                <el-input v-model="kgForm.answer" placeholder="每题英文逗号隔开，格式：A,B,AB,或T,T,F,F，"></el-input>
                            </el-tooltip>
                        </el-form-item>
                        <el-form-item v-if="isAb" label="B卷答案">
                          <el-tooltip class="item" effect="dark" content="格式：A,B,AB,或T,T,F,F，每题英文逗号隔开" placement="top">
                            <el-input v-model="kgForm.answerB" placeholder="每题英文逗号隔开，格式：A,B,AB,或T,T,F,F，"></el-input>
                          </el-tooltip>
                        </el-form-item>
                        <el-form-item >
                          <el-button type="text" size="mini" @click="kgFromSubmit">设置</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table v-loading="loading" :data="kgData" style="width: 100%" height="400" :span-method="spanMethod" align='center'>
                        <el-table-column prop="topic_index" label="序号" min-width="30" align='center' ></el-table-column>
                        <el-table-column prop="topic_no" label="题号"  min-width="30" align='center' ></el-table-column>
                        <el-table-column prop="topic_type" label="题型"  min-width="50" align='center' :formatter="formatterTopicType"></el-table-column>
                        <el-table-column prop="remark" label="备注"  width="120" align='center' >
                          <template slot-scope='{row}'>
                            <el-input v-model="row.remark" style="width: 100px;"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="变更" max-width="80" align='center'  >
                            <template slot-scope='scope'>
                                <el-button type="text" size="mini" v-if="scope.row.topic_type =='SingleChoice'" @click="changeType(scope.row)">改为多选题</el-button>
                                <el-button type="text" size="mini" v-if="scope.row.topic_type =='MultiChoice'" @click="changeType(scope.row)">改为单选题</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="score_count" :label="isAb?'A卷选项':'选项'"  :width="kgWidth" align='center' >
                            <template slot-scope='scope'>
                                <el-radio-group v-model="scope.row.answer" size="small" v-if="scope.row.topic_type =='SingleChoice'">
                                    <el-radio-button :label="getChooose(scope.row.topic_type, c)" v-for=" c in scope.row.option_count" style="margin-left: 4px;"></el-radio-button>
                                </el-radio-group>
                                <el-checkbox-group v-model="scope.row.answer" size="small" v-if="scope.row.topic_type =='MultiChoice'">
                                    <el-checkbox-button :label="c" v-for="c in turnChoooses(scope.row.option_count)" :key="c" style="margin-left: 4px;"></el-checkbox-button>
                                </el-checkbox-group>
                                <el-radio-group v-model="scope.row.answer" size="small" v-if="scope.row.topic_type =='Judge'">
                                    <el-radio-button :label="getChooose(scope.row.topic_type, c)" v-for=" c in scope.row.option_count" style="margin-left: 8px;"></el-radio-button>
                                </el-radio-group>
                            </template>
                        </el-table-column>
                        <el-table-column prop="topic_no" label="多选规则"  min-width="50" align='center' >
                            <template slot-scope='scope'>
                                <el-button type="text" size="mini" v-if="scope.row.topic_type =='MultiChoice'"
                                           :style="scope.row.score_rules!=null?{color: 'red'}:null"
                                           @click="specialMark(scope.row)">特殊规则</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="score_count" v-if="isAb" label="B卷选项" :width="kgWidth" align='center' >
                            <template slot-scope='scope'>
                                <el-radio-group v-model="scope.row.answer_b" size="small" v-if="scope.row.topic_type =='SingleChoice'">
                                    <el-radio-button :label="getChooose(scope.row.topic_type, c)" v-for=" c in scope.row.option_count" style="margin-left: 4px;"></el-radio-button>
                                </el-radio-group>
                                <el-checkbox-group v-model="scope.row.answer_b" size="small" v-if="scope.row.topic_type =='MultiChoice'">
                                    <el-checkbox-button :label="c" v-for=" c in turnChoooses(scope.row.option_count)" :key="c" style="margin-left: 4px;"></el-checkbox-button>
                                </el-checkbox-group>
                                <el-radio-group v-model="scope.row.answer_b" size="small" v-if="scope.row.topic_type =='Judge'">
                                    <el-radio-button :label="getChooose(scope.row.topic_type, c)" v-for=" c in scope.row.option_count" style="margin-left: 8px;"></el-radio-button>
                                </el-radio-group>
                            </template>
                        </el-table-column>
                        <el-table-column prop="score" min-width="100" label="分数" align='center' >
                            <template slot-scope='scope'>
                                <el-input v-model="scope.row.score" v-if="'batchSet' in scope.row" placeholder="设置分数" @blur="batchScore(scope.row.topic_type, scope.row.score)" style="width: 100px;"></el-input>
                                <el-input v-model="scope.row.score" v-else placeholder="设置分数" @blur="calcCount" style="width: 100px;"></el-input>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="主观题设置" name="zg">
                    <el-form :inline="true" :model='zgForm' ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
                        <el-form-item label="批量设置分数">
                            <el-input v-model="zgForm.score" placeholder="分数" @blur="changeScore(2)"  @keyup.enter.native='changeScore(2)'></el-input>
                        </el-form-item>
                    </el-form>
                    <el-table v-loading="loading" :data="zgData" style="width: 100%" height="400" align='center'>
                        <el-table-column prop="topic_index" label="系统号" min-width="30" align='center' ></el-table-column>
                        <el-table-column prop="topic_no" label="题号"  min-width="30" align='center' ></el-table-column>
                        <el-table-column prop="remark" label="备注"  width="120" align='center' >
                          <template slot-scope='{row}'>
                            <el-input v-model="row.remark" style="width: 100px;"></el-input>
                          </template>
                        </el-table-column>
                        <el-table-column prop="topic_type" label="题型" min-width="80" align='center' :formatter="formatterTopicType" ></el-table-column>
                        <el-table-column prop="score" min-width="80" label="分数" align='center' >
                            <template slot-scope='scope'>
                                <el-input v-model="scope.row.score" placeholder="设置分数" @blur="calcCount" style="width: 80px;"></el-input>
                            </template>
                        </el-table-column>

                    </el-table>
                </el-tab-pane>

            </el-tabs>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" v-if="state< 2" v-has-role="['teacher']" @click="submitForm">保 存</el-button>
            </div>
        </el-dialog>
        <topicSpecialDialog ref="topicSpecialDialog" :topicSpecial="selTopic" @ok="setScoreRule"></topicSpecialDialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'
import topicSpecialDialog from "./topicSpecialDialog";

    export default {
        name: 'newExamTopicDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '分数设置', kgWidth: 300,
                dialogVisible:false, loading: false, activeName: 'kg',
                kgData: [], zgData: [], kgIndexList: [], examId: null, isAb: false, selTopic: null, state: 0,
                kgForm: { startIndex: null, endIndex: null, score: null, answer: null, answerB: null},
                zgForm: {score: null,},
                examSheetForm: {examId: null, sheetId: null, sheetName: null},
                newExamTopicForm: { examId: null, sheetId: null, kgCount: 0, zgCount: 0, kgScore: 0, zgScore: 0, },
                kgFormRules: {
                    startIndex: [{ required: true, message: '请选择开始序号', trigger: 'blur' }],
                    endIndex: [{ required: true, message: '请选择结束序号', trigger: 'blur' }],
                    score: [{ required: true, message: '请输入分数', trigger: 'blur' }],
                }
            }
        },
        components:{
            topicSpecialDialog
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            newExamTopic: {
                type: Object
            }
        },
        watch: {
            newExamTopic: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("newExamTopic", n);
                    if(n){
                        this.examId = n.id;
                        this.state = n.state;
                        this.kgForm = {score: null,};
                        this.zgForm = {score: null,};
                        this.myTitle = n.id + ":" + n.name + "-分数设置"
                        this.loadOne();
                        this.loadTopicList();
                    }
                }
            },
        },
        methods: {
            showDialog(){

                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            tabClick(){

            },
            loadOne(){

                let param = { controllerName: 'akExamSheetConfig/', methodName: 'one', param: { id: this.examId }};
                simpleApi.post(param).then(({result: {code, data}}) => {
                    console.log('load one', data);
                    if(data){
                        this.examSheetForm.examId = data.examId; this.examSheetForm.sheetId = data.sheetId; this.examSheetForm.sheetName = data.sheetName;
                        if(data.isAb == 1)  this.isAb = true; else this.isAb = false;
                    }
                });
            },
            loadTopicList(){
                if(this.examId == null) return ;
                this.kgIndexList = [];
                let search = { examId: this.examId, orderBy:  'topic_index'};
                let param = { controllerName: 'akExamTopic', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadTopicList", data);
                    let maxAnwCount = 4;
                    if (data.code == 0) {
                        let list = data.result;
                        let kgCount=0, zgCount=0, kgScore=0, zgScore = 0;
                        let kgList = [], zgList = [], sList = [], mList = [], jList = [];
                        for (let i=0; i<list.length; i++){
                            let d = list[i];
                            if(d.topic_type2 == 'Object'){
                                kgCount = kgCount + 1;
                                kgScore = kgScore+((d.score!=null)?parseFloat(d.score): 0);
                                if(d.topic_type == 'SingleChoice') sList.push(d);
                                if(d.topic_type == 'MultiChoice'){
                                    if(d.answer==null) d.answer = []; else d.answer = d.answer.split(',');
                                    if(this.isAb) {
                                        if(d.answer_b==null) d.answer_b = []; else d.answer_b = d.answer_b.split(',');
                                    }
                                    mList.push(d);
                                }
                                if(d.topic_type == 'Judge') jList.push(d);
                                this.kgIndexList.push(d.topic_index);
                                if(d.option_count > maxAnwCount) maxAnwCount = d.option_count;
                            }else{
                                zgCount = zgCount + 1;
                                zgScore = zgScore+((d.score!=null)?parseFloat(d.score): 0);
                                zgList.push(d);
                            }
                        }
                        let sdList = [], mdList = [], jdList = [];
                        if(sList.length > 0) sdList = [{batchSet: 'Y', topic_index: '单选题 - 可在该栏分值输入框内，为以下单选题型的所有题目批量设置统一分值', topic_type: 'SingleChoice', score: null, remark: null}]
                        if(mList.length > 0) mdList = [{batchSet: 'Y', topic_index: '多选题 - 可在该栏分值输入框内，为以下多选题型的所有题目批量设置统一分值', topic_type: 'MultiChoice', score: null, remark: null}]
                        if(jList.length > 0) jdList = [{batchSet: 'Y', topic_index: '判断题 - 可在该栏分值输入框内，为以下判断题型的所有题目批量设置统一分值', topic_type: 'Judge', score: null, remark: null}]
                        kgList = [...sdList, ...sList, ...mdList, ...mList, ...jdList, ...jList];
                        console.log("calcCount", kgCount, zgCount, kgScore, zgScore);
                        this.newExamTopicForm.kgCount = kgCount;
                        this.newExamTopicForm.kgScore = kgScore.toFixed(1);
                        this.newExamTopicForm.zgCount = zgCount;
                        this.newExamTopicForm.zgScore = zgScore.toFixed(1);
                        this.kgData = kgList;
                        this.zgData = zgList;
                        this.kgWidth = maxAnwCount * 60;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            spanMethod({ row, column, rowIndex, columnIndex }) {
                if ("batchSet" in row) {
                    if (columnIndex == 0) {
                        return [1, 6];
                    }else if(columnIndex > 0 && columnIndex <6){
                        return [0, 0];
                    }
                }
            },

            changeType(row){
                let topicType = row.topic_type;
                let myType = "MultiChoice";
                let msg = "将所在的题块由单选题变更为多选题，是否继续？";
                if(topicType =='SingleChoice'){
                    msg = "将所在的题块由单选题变更为多选题，是否继续？";
                    myType = "MultiChoice";
                }
                if(topicType =='MultiChoice'){
                    myType = "SingleChoice";
                    msg = "将所在的题块由多选题变更为单选题，是否继续？";
                }
                let that = this;
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    let param = { controllerName: 'akExamTopic', methodName: '/changeTopicType',
                        param: { id: row.id, examId: row.exam_id, topicIndex: row.topic_index, topicType: myType }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '变更成功!'});
                            that.loadTopicList()
                        }
                    });
                }).catch(() => {

                });
            },
            formatterTopicType(row, column, cellValue, index) {

                if(cellValue == 'Object') return '客观题';
                if(cellValue == 'SingleChoice') return '单选题';
                if(cellValue == 'MultiChoice') return '多选题';
                if(cellValue == 'Judge') return '判断题';
                if(cellValue == 'FillBlank') return '填空题';
                if(cellValue == 'Subjective') return '主观题';
                if(cellValue == 'AnswerQuestion') return '解答题';
                if(cellValue == 'Composition') return '语文作文题';
                if(cellValue == 'CNComposition') return '语文作文题';
                if(cellValue == 'EnglishComposition') return '英语作文题';
                return cellValue;
            },
            turnChoooses(cou){
                let myList = [];
                for (let i=0; i<cou; i++){
                    let c = '';
                    if(i==0) c = "A";
                    if(i==1) c = "B";
                    if(i==2) c = "C";
                    if(i==3) c = "D";
                    if(i==4) c = "E";
                    if(i==5) c = "F";
                    if(i==6) c = "G";
                    if(i==7) c = "H";
                    if(i==8) c = "I";
                    if(i==9) c = "J";
                    if(i==10) c = "K";
                    if(i==11) c = "L";
                    if(i==12) c = "M";
                    if(i==13) c = "N";
                    myList.push(c);
                }
                return myList;
            },
            getChooose(t, c){
                let i = c-1;
                if(t == 'SingleChoice' || t == 'MultiChoice'){
                    if(i==0) return "A";
                    if(i==1) return "B";
                    if(i==2) return "C";
                    if(i==3) return "D";
                    if(i==4) return "E";
                    if(i==5) return "F";
                    if(i==6) return "G";
                    if(i==7) return "H";
                    if(i==8) return "I";
                    if(i==9) return "J";
                    if(i==10) return "K";
                    if(i==11) return "L";
                    if(i==12) return "M";
                    if(i==13) return "N";
                }
                if(t == 'Judge') {
                    if (i == 0) return "T";
                    if (i == 1) return "F";
                }
            },
            batchScore(topicType, score){
                console.log("batchScore", topicType, score);
                for (let i=0; i<this.kgData.length; i++){
                    let d = this.kgData[i];
                    if (!("batchSet" in d)) {
                        console.log("batchScore", i, d);
                        if(d.topic_type == topicType) d.score = score;
                    }
                }
                this.$set(this, "kgData", this.kgData);
                this.calcCount();
            },
            changeScore(v){
                let score = this.kgForm.score;
                let list = [];
                if(v==1){
                    score = XEUtils.toNumber(this.kgForm.score);
                    list = this.kgData;
                }else{
                    score = XEUtils.toNumber(this.zgForm.score);
                    list = this.zgData;
                }
                console.log("changeScore", v, score, list);
                for (let i=0; i<list.length; i++){
                    let d = list[i];
                    if (!("batchSet" in d)) {
                        d.score = score;
                    }
                }
                if(v==1) {
                    this.$set(this, "kgData", list);
                }else{
                    this.$set(this, "zgData", list);
                }
                this.calcCount();
            },

            calcCount(){
                let kgCount=0, zgCount=0, kgScore=0.0, zgScore = 0.0;
                for (let i=0; i<this.kgData.length; i++){
                    let d = this.kgData[i];
                    if (!("batchSet" in d)) {
                        kgCount = kgCount + 1;
                        kgScore = kgScore+((d.score!=null)?parseFloat(d.score): 0);
                    }
                    // console.log("kg", d.score, kgScore);
                }
                for (let i=0; i<this.zgData.length; i++){
                    let d = this.zgData[i];
                    zgCount = zgCount + 1;
                    zgScore = zgScore+((d.score!=null)?parseFloat(d.score): 0);
                }
                console.log("calcCount", kgCount, zgCount, kgScore, zgScore);
                this.newExamTopicForm.kgCount = kgCount;
                this.newExamTopicForm.kgScore = kgScore;
                this.newExamTopicForm.zgCount = zgCount;
                this.newExamTopicForm.zgScore = zgScore;
            },
            specialMark(row){
                this.selTopic = row;
                this.$refs.topicSpecialDialog.showDialog();
            },
            setScoreRule(topicSpecialForm){
                let ar = XEUtils.find(this.kgData, item => {
                    return item.topic_index == topicSpecialForm.topicIndex;
                });
                this.selTopic = null;
                if(ar){
                  if(topicSpecialForm.scoreRules != null){
                    ar.score_rules = JSON.stringify(topicSpecialForm.scoreRules);
                    console.log("setScoreRule2", ar);
                  }else{
                    ar.score_rules = null;
                  }
                    this.$set(this, "kgData", this.kgData);
                }

            },
            kgFromSubmit(){
                let that = this;
                this.$refs.kgForm.validate((valid) => {
                    if (valid) {
                        if(that.kgForm.startIndex > that.kgForm.endIndex){
                            that.$message.error("起始序号不能大于结束序号");
                            return ;
                        }
                        let answer = that.kgForm.answer;
                        let a_i = 0;
                        let anArr = [], bnArrB = [];
                        if(answer != null && answer != ""){
                            anArr = answer.toUpperCase().split(",");
                        }
                        let answerB = that.kgForm.answerB;
                        if(answerB != null && answerB != ""){
                          bnArrB = answerB.toUpperCase().split(",");
                        }
                        let iScore = XEUtils.toNumber(that.kgForm.score);
                        let startIdx = that.kgForm.startIndex;
                        let endIdx = that.kgForm.endIndex;
                        for (let i=0; i<=that.kgData.length; i++){
                            let d = that.kgData[i];
                            if (d && d !=null && (!("batchSet" in d)) && d.topic_index >= startIdx && d.topic_index <= endIdx) {
                                if(d.topic_type2 == 'Object'){
                                    d.score = iScore;
                                }
                                if(d.topic_type == 'SingleChoice' || d.topic_type == 'Judge'){
                                    if(a_i < anArr.length){ d.answer = anArr[a_i];}
                                    if(a_i < bnArrB.length){ d.answer_b = bnArrB[a_i];}
                                    a_i++;
                                }else if(d.topic_type == 'MultiChoice'){
                                    if(a_i < anArr.length){ d.answer = anArr[a_i].split("");}
                                    if(a_i < bnArrB.length){ d.answer_b = bnArrB[a_i].split("");}
                                    a_i++;
                                }
                            }
                        }
                        this.$set(this, "kgData", that.kgData);
                    }
                });
            },
            submitForm(){
                let that = this;
                let myKgData = [];
                for (let i=0; i<this.kgData.length; i++){
                    let d = this.kgData[i];
                    if (!("batchSet" in d)) {
                        myKgData.push(d);
                    }
                }
                let topList = [...myKgData, ...this.zgData];
                console.log('topList', topList);
                for (let i=0; i<topList.length; i++){
                    let d = topList[i];
                    if(d.answer == null && d.topic_type2 == 'Object'){
                        this.$message.error("答案全部需要设置，序号：" + d.topic_index);
                        return ;
                    }
                    if(this.isAb && d.answer_b == null && d.topic_type2 == 'Object'){
                        this.$message.error("AB卷答案全部需要设置，序号：" + d.topic_index);
                        return ;
                    }
                    if(d.score == null || d.score ==0){
                        this.$message.error(d.topic_index + "," + d.topic_no + ", 分数全部需要设置");
                        return ;
                    }

                }
                this.loading = true;
                let paramData = {examId: this.examId, topList: topList};
                let param = { controllerName: 'akExamTopic', methodName: '/update', param: paramData};

                simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log('newExamTopic', code);
                    if (code == 0) {
                        this.dialogVisible = false;
                        that.$emit('ok');
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });

            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .newExamTopicDialog{
        .el-dialog__body{
            padding: 0px 20px 10px 20px;
        }
        .my-title{
            font-size: 24px;
            color: #1A1A1A;
            font-weight: bold;
        }
        .title-desc{
            font-size: 12px;
            color: #808080;
        }
    }


</style>


