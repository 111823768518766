<template>
    <div class="bindExamDialog">
        
        <el-dialog :title="myTitle" width="50%"  :visible.sync="dialogVisible" :close-on-click-modal="false">

            <el-tabs v-model="activeName" @tab-click="tabClick">
                <el-tab-pane label="选择答题卡" name="kg">
                    <el-row>
                        <el-col :span="24">
                            <el-form :inline="true" :model='searchForm' ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
                                <el-form-item label="名称" >
                                    <el-input v-model="searchForm.searchText" placeholder="请输入答题卡名称" @keyup.enter.native="searchScantron"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" size ="mini" icon="search" @click='searchScantron'>查询</el-button>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>

                    <el-table v-loading="loading" :data="tableData" style="width: 100%"  height="400" align='center'>
                        <el-table-column prop="id" label="key"  align='center' ></el-table-column>
                        <el-table-column prop="name" label="考试名称"  align='center' ></el-table-column>
                        <el-table-column prop="create_time" label="创建时间" min-width="120" align='center' >
                            <template slot-scope='scope'>
                                {{ scope.row.create_time | formatTime }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="operation" align='center' label="操作" min-width="120">
                            <template slot-scope='scope'>
                                <el-button type="primary" size="mini" @click='selectScantron(scope.row)'>选择</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="导入答题卡" name="zg">
                    <el-upload class="upload-demo" drag action
                               :multiple="false"  :action="uploadUrl" :headers="uploadHeader"
                               :show-file-list="false"
                               accept="pdf, application/pdf"
                               :on-success="handleChange" :auto-upload="true">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                        <div class="el-upload__tip" slot="tip">只能上传pdf文件，自动关联答题卡</div>
                    </el-upload>
                </el-tab-pane>

            </el-tabs>
            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>

            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'bindExamDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '绑定答题卡',
                dialogVisible:false, loading: false, activeName: 'kg',
                tableData: [], examId: null, uploadUrl: null,
                bindExamForm: { examId: null, sheetId: null },
                searchForm: {  searchText: null },
                uploadHeader: {
                    'Company-url': 'http://www.szpcyl.com',
                    'X-Powered-By': 'raozq',
                    'ke-Token': 'noLogin',
                    'ke-Domain': 'WB',
                    // 'Content-Type':'multipart/form-data'
                },
            }
        },
        components:{
        },
        filters: {
            formatTime(v) {
                return mUtils.formatDate(new Date(v), 4)
            }
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
                userToken: state => state.user.token,
            }),
        },
      	mounted() {

	    },
        props: {
            bindExam: {
                type: Object
            }
        },
        watch: {
            bindExam: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.examId = n.id;
                      this.myTitle = n.name + "(" +n.id+")";
                        let myHost = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
                        this.uploadUrl = myHost + 'akExam/uploadAnswerSheet?examId='+n.id;
                        this.uploadHeader["ke-Token"] = this.userToken;
                        this.searchScantron();
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            tabClick(){
                
            },
            handleChange(response, fileList) {
                console.log(response, fileList);
                if(response  && ("file" in response) && ("response" in response.file)){
                    let resp = response.file.response;
                    if(resp.status == "success"){
                        this.$message({ type: 'success', message: '上传成功!'});
                        this.dialogVisible = false;
                        this.$emit('ok');
                        return ;
                    }
                }
                this.$message({ type: 'success', message: '上传失败!'});
            },

            searchScantron(){
                let search = { createrId: this.userInfo.id, searchText: mUtils.searchText(this.searchForm.searchText), orderBy: 'id desc'};
                let param = { controllerName: 'akExam', methodName: '/scantronList', pageNo: 0, pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    if (data.code == 0) {
                        this.tableData = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            selectScantron(row){
                let that = this;
                this.loading = true;
                let param = { controllerName: 'akExam', methodName: '/bindRespondent', param: {examId: this.examId, sheetId: row.id}};
                simpleApi.post(param).then(({ code, data}) =>  {
                    console.log('bindExam', code);
                    if (code == 0) {
                        that.dialogVisible = false;
                        that.$emit('ok');
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .bindExamDialog{

    }


</style>


