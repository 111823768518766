<template>
    <div class="topicSpecialDialog">
        
        <el-dialog :title="myTitle" width="50%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row>
                <el-col :span="24" v-if="tableData.length > 0">
                    <span>本题共有{{tableData.length}}种组合</span>
                </el-col>
            </el-row>

            <el-form :inline="true"  ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
                <el-form-item label="批量设置分数">
                    <el-input v-model="batchScore" placeholder="分数" @blur="changeScore"  @keyup.enter.native='changeScore'></el-input>
                </el-form-item>
<!--                <el-form-item label="生成组合">-->
<!--                    <el-button type="primary"  @click="genChoose">生 成</el-button>-->
<!--                </el-form-item>-->
                <el-form-item label="">
                    <el-button type="primary"  @click="addRule">添加规则</el-button>
                    <el-button type="primary"  @click="answerGroup">生成</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" height="400" align='center'>

                <el-table-column prop="score_count" label="组合"  align='center' >
                    <template slot-scope='scope'>

                        <el-checkbox-group v-model="scope.row.answer" size="small">
                            <el-checkbox-button :label="c" v-for="c in chooosesList" :key="c" style="margin-left: 4px;"></el-checkbox-button>
                        </el-checkbox-group>

                    </template>
                </el-table-column>
                <el-table-column prop="score" min-width="80" label="分数" align='center' >
                    <template slot-scope='scope'>
                        <el-input v-model="scope.row.score" placeholder="设置分数" style="width: 80px;"></el-input>
                    </template>
                </el-table-column>
                <el-table-column  label="操作" align='center' >
                    <template slot-scope='scope'>
                        <el-button type="text" @click="delChoose(scope.$index)">删 除</el-button>
                    </template>
                </el-table-column>

            </el-table>

            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'topicSpecialDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '漏选得分设置',
                dialogVisible:false, loading: false, batchScore: null,
                tableData: [], chooosesList: [], tempArr:[], answerArr: [],
                topicSpecialForm: { examId: null, topicIndex: null, score: 0, answer: null, optionCount: 0, scoreRules: null, },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            topicSpecial: {
                type: Object
            }
        },
        watch: {
            topicSpecial: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.topicSpecialForm.examId = n.id;
                        this.topicSpecialForm.score = n.score;
                        this.topicSpecialForm.answer = n.answer;
                        this.topicSpecialForm.topicIndex = n.topic_index;
                        this.topicSpecialForm.optionCount = n.option_count;
                        if(n.score_rules != null){
                            if(XEUtils.isString(n.score_rules)){
                              let myRule = JSON.parse(n.score_rules);
                              console.log("srules", myRule);
                                if(XEUtils.isString(myRule)){
                                    myRule = JSON.parse(myRule);
                                }
                                this.topicSpecialForm.scoreRules = myRule;
                            }else{
                                this.topicSpecialForm.scoreRules = n.score_rules;
                            }
                          console.log("rules", this.topicSpecialForm.scoreRules);
                          this.tableData = [];
                            for (let i=0; i<this.topicSpecialForm.scoreRules.length; i++){
                                let d = this.topicSpecialForm.scoreRules[i];
                                console.log("d", d);
                                if(("answer" in d))
                                    this.tableData.push({answer: d.answer, score: d.score});
                            }
                        }else{
                            this.tableData = [];
                            this.topicSpecialForm.scoreRules = null;
                        }
                        this.makeChoooses(n.option_count);

                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            changeScore(){
                let score = this.batchScore;

                for (let i=0; i<this.tableData.length; i++){
                    let d = this.tableData[i];
                    d.score = score;
                }
                this.$set(this, "tableData", this.tableData);
            },
            makeChoooses(cou){
                let myList = [];
                for (let i=0; i<cou; i++){
                    let c = '';
                    if(i==0) c = "A";
                    if(i==1) c = "B";
                    if(i==2) c = "C";
                    if(i==3) c = "D";
                    if(i==4) c = "E";
                    if(i==5) c = "F";
                    if(i==6) c = "G";
                    if(i==7) c = "H";
                    if(i==8) c = "I";
                    if(i==9) c = "J";
                    myList.push(c);
                }
                this.chooosesList = myList;
            },
            answerGroup(){
                let arr = [...this.topicSpecialForm.answer];
                console.log("arr", arr);
                if(arr.length == 0) return;
                this.tableData = [];
                let len = arr.length;
                let allAnw = [];
                for (let i=1; i<len; i++){
                    let r = this.combination(arr, i, false)
                    allAnw = [...allAnw, ...r]
                    for (let j=0; j<r.length; j++){
                        this.tableData.push({answer: r[j], score: 1,});
                    }
                }
                console.log("answerGroup", allAnw);
            },
            combination(source, count, isPermutation = true) {
                //如果只取一位，返回数组中的所有项，例如 [ [1], [2], [3] ]
                let currentList = source.map((item) => [item]);
                if (count === 1) {
                    return currentList;
                }
                let result = [];
                //取出第一项后，再取出后面count - 1 项的排列组合，并把第一项的所有可能（currentList）和 后面count-1项所有可能交叉组合
                for (let i = 0; i < currentList.length; i++) {
                    let current = currentList[i];
                    //如果是排列的方式，在取count-1时，源数组中排除当前项
                    let children = [];
                    if (isPermutation) {
                        children = this.combination(source.filter(item => item !== current[0]), count - 1, isPermutation);
                    }
                    //如果是组合的方法，在取count-1时，源数组只使用当前项之后的
                    else {
                        children = this.combination(source.slice(i + 1), count - 1, isPermutation);
                    }
                    for (let child of children) {
                        result.push([...current, ...child]);
                    }
                }
                return result;
            },
            addRule(){
                this.tableData.push({answer: [], score: 1,});
            },
            genChoose(){
                this.tableData = [];
                this.tempArr = [];
                this.genArr(this.chooosesList);
                console.log('tempArr', this.tempArr);
            },
            genArr(arr) {
                for (var i = 0; i < arr.length; i++) {
                    // 插入第i个值
                    this.tempArr.push(arr[i]);
                    // 复制数组
                    var copy = arr.slice();
                    // 删除复制数组中的第i个值，用于递归
                    copy.splice(i, 1);
                    if(copy.length == 0) {
                        // 如果复制数组长度为0了，则打印变量
                        console.log(this.tempArr);
                    }else {
                        // 否则进行递归
                        this.genArr(copy);
                    }
                    // 递归完了之后删除最后一个元素，保证下一次插入的时候没有上一次的元素
                    this.tempArr.pop();
                }
            },
            delChoose(index){
                console.log("delChoose", index);
                this.tableData.splice(index, 1);
            },
            submitForm(){
                let scoreRules = [];
                for (let i=0; i<this.tableData.length; i++){
                    let d = this.tableData[i];
                    scoreRules.push({answer: d.answer, score: d.score});
                }
                if(scoreRules.length == 0) this.topicSpecialForm.scoreRules = null;
                else this.topicSpecialForm.scoreRules = scoreRules;
                this.dialogVisible = false;
                console.log("scoreRules", scoreRules);
                this.$emit('ok', this.topicSpecialForm);
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .topicSpecialDialog{

    }


</style>


