<template>
    <div class="newExamDialog">
        <el-dialog :title="myTitle" width="720px"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="newExamForm" ref="newExamDialogForm" :rules="newExamRules" label-suffix=":">
                <el-form-item label="考试名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="newExamForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="考试类型"  prop="category" :label-width="formLabelWidth">
                    <el-select v-model="newExamForm.category" filterable placeholder="请选择" style="width: 100%">
                        <el-option v-for="item in examType" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属课程"  prop="courseId" :label-width="formLabelWidth">
                    <el-select v-model="newExamForm.courseId" filterable placeholder="请输入课程名称或编号" remote :remote-method="remoteCourse" style="width: 100%">
                        <el-option v-for="item in courceList" :key="item.id" :label="item.name+'('+item.course_no+')'" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="学年学期" prop="semester" :label-width="formLabelWidth" >
                    <el-select v-model="newExamForm.semester" filterable placeholder="请选择" style="width: 100%">
                        <el-option key="" label="全部" value=""></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考试人数"  prop="joinCount" :label-width="formLabelWidth">
                    <div style="text-align: left;">
                        <el-input-number v-model="newExamForm.joinCount" :min="1" ></el-input-number>
                    </div>

                </el-form-item>
                <el-form-item label="考试时间"  prop="examTime" :label-width="formLabelWidth">
                    <div style="text-align: left;">
                        <el-date-picker v-model="newExamForm.examTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                        </el-date-picker>
                    </div>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'newExamDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                courceList: [], acadyearTermList: [],
                examType: [{name: '期中', value: 0}, {name: '期末', value: 1}, {name: '补考', value: 2}, {name: '测验', value: 3}, {name: '其他', value: 4}],
                courceForm: {id: null, searchText: null},
                newExamForm:{ id: null, licenceId: null, schoolId: null, courseId: null, name: null, semester: null, joinCount: null,
                    category: null, examTime: null, state: 0, createrId: null  },
                newExamRules: {
                    name: [{ required: true, message: '请输入考试名称', trigger: 'blur' }, { validator: this.checkName, trigger: "blur"  }],
                    category: [{ required: true, message: '请选择考试类型', trigger: 'blur' }],
                    semester: [{ required: true, message: '请选择学年学期', trigger: 'blur' }],
                    joinCount: [{ required: true, message: '请输入考试人数', trigger: 'blur' }],
                    courseId: [{ required: true, message: '请选择课程', trigger: 'blur' }],
                    examTime: [{ required: true, message: '请选择考试时间', trigger: 'blur' }],
                    
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadCourceList();
            this.loadAcadyearTermList();
	    },
        props: {
            newExam: {
                type: Object
            }
        },
        watch: {
            newExam: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.newExamForm = { id: n.id, licenceId: this.userInfo.licenceId, courseId: n.course_id, name: n.name, semester: n.semester, joinCount: n.join_count,
                            category: n.category, examTime2: null, examTime: null, schoolId: this.userInfo.schoolId, state: 0, createrId: this.userInfo.id };
                        this.courceForm.id = n.course_id; this.courceForm.searchText = null;
                        this.loadCourceList();
                        if(n.exam_time != null){
                            this.newExamForm.examTime = XEUtils.toStringDate(n.exam_time);
                        }

                        if(n.id == null) this.myTitle = '新建考试'; else this.myTitle = '编辑考试';
                    }else{
                        this.newExamForm = { id: null, licenceId: this.userInfo.licenceId, schoolId: this.userInfo.schoolId,
                            courseId: null, name: null, semester: null, joinCount: null,
                            category: null, examTime: new Date(), state: 0, createrId: this.userInfo.id};
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            remoteCourse(query){
                if(query !== ''){
                    this.courceForm.id = null;
                    this.courceForm.searchText = query;
                    this.courceList = [];
                    this.loadCourceList();
                }
            },
            checkName(rule, value, callback){
                let specialKey = "@[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'\t";
                for (let i = 0; i < value.length; i++) {
                    if (specialKey.indexOf(value.substr(i, 1)) > -1) {
                        callback(new Error('标题不能包含/\\*:?"<>| 等特殊字符'));
                        return
                    }
                }
                callback();
            },
            loadCourceList(){
                let search = { licenceId: this.userInfo.licenceId, //schoolId: this.userInfo.schoolId,
                    id: mUtils.searchText(this.courceForm.id),  searchText: mUtils.searchText(this.courceForm.searchText)};
                let param = { controllerName: 'sclCource', methodName: '/list', pageNo: 0, pageSize: 50, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    if (data.code == 0) {
                        this.courceList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+2); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            submitForm(){
                console.log(this.newExamForm);
                let that = this;
                this.$refs.newExamDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.newExamForm.licenceId = this.userInfo.licenceId;
                        // this.newExamForm.examTime = XEUtils.toDateString(this.newExamForm.examTime2, 'yyyyMMddHHmmssSSS');
                        let methodName = (this.newExamForm.id==null)?'/insert':'/update';
                        let param = { controllerName: 'akExam', methodName: methodName, param: this.newExamForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('newExam', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .newExamDialog{

    }


</style>


