<template>
    <div class="qualityDialog">

        <el-dialog :title="myTitle" width="900px"  :visible.sync="dialogVisible" :close-on-click-modal="false">
          <el-table v-loading="loading" :data="tableData" :span-method="objectSpanMethod" style="width: 100%" height="400" align='center'>
            <el-table-column prop="exam_seq" label="题序号" align='center' ></el-table-column>
            <el-table-column prop="name" label="用户名"   align='center' ></el-table-column>
            <el-table-column prop="nick_name" label="教师姓名"   align='center' ></el-table-column>
            <el-table-column prop="cou" label="已阅题数" align='center'  ></el-table-column>
            <el-table-column prop="right_score" label="题满分" align='center'  ></el-table-column>
            <el-table-column prop="max_score" label="最高分" align='center'  ></el-table-column>
            <el-table-column prop="min_score" label="最低分" align='center'  ></el-table-column>
            <el-table-column prop="avg_score" label="平均分" align='center' :formatter="formatterScore"  ></el-table-column>

          </el-table>
          <div slot="footer" class="dialog-footer">
              <el-button @click="cancel">关 闭</el-button>
          </div>

        </el-dialog>

    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'qualityDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '设置',
                dialogVisible:false, loading: false,
                qualityData: [], tableData: [],
                examId: null,
            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            quality: {
                type: Object
            }
        },
        watch: {
            quality: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log("quality", n);
                    if(n){
                        this.examId = n.id;
                        this.examName = n.name;
                        this.myTitle = n.id + ":" + n.name + "-阅卷质量"
                        this.loadQualityList();
                    }
                }
            },
        },
        methods: {
          showDialog(){
              this.dialogVisible = true;
          },
          hideDialog(){
              this.dialogVisible = false;
          },
          cancel(){
              this.dialogVisible = false;
              this.$emit('cancel');
          },
          formatterScore(row, column, cellValue, index) {
            if(cellValue && cellValue != null){
              return cellValue.toFixed(2);
            }
            return "";

          },
          objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
              if (("rowspan" in row) && row["rowspan"] > 0) {
                console.log("objectSpanMethod", rowIndex, row["rowspan"] )
                return {
                  rowspan: row["rowspan"],
                  colspan: 1
                };
              } else {
                return {
                  rowspan: 0,
                  colspan: 0
                };
              }
            }

          },

          loadQualityList(){

                let search = { examId: this.examId, licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'exam/', methodName: 'qualityList',  param: search};
                return simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (code == 0) {
                      let list = data.list;
                      if(list && list.length > 0){
                        let first = list[0]
                        let irow = 1
                        for (let i=0; i<list.length; i++){
                          let t = list[i];
                          if(i > 0 && t.exam_seq == list[i-1].exam_seq){
                            irow++
                          }else{
                            first["rowspan"] = irow
                            first = t
                            irow = 1
                          }
                        }
                        first["rowspan"] = irow
                      }
                      this.tableData = list;
                      console.log("loadQualityList", list)
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },



        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .qualityDialog{

    }


</style>


