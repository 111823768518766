<template>
    <div class="progressDialog">
        <el-dialog :title="myTitle" width="650px"  :visible.sync="dialogVisible" :close-on-click-modal="false">

            <el-row  style="padding: 10px;">
                <el-col :span="6" style="text-align: left;" >总进度：{{totalCount.myText}}</el-col>
                <el-col :span="18">
                    <el-progress :color="customColors" :percentage="totalCount.percent"></el-progress>
                </el-col>
            </el-row>
            <div  v-for="item in progressList" style="margin-top: 8px; padding: 10px;">
                <el-row>
                    <el-col :span="6" style="text-align: left;">{{item.examSeq}}:</el-col>
                    <el-col :span="18">
                        <el-progress :color="customColors" :percentage="item.percent"></el-progress>
                    </el-col>
                </el-row>
                <el-row v-for="ti in item.teacherList" style="margin-top: 2px; padding: 3px 15px;">
                    <el-col :span="6" style="text-align: left;" :offset="1">{{ti.teacherName}}({{ti.yyCount}}/{{ti.total}}):</el-col>
                    <el-col :span="17">
                        <el-progress :color="customColors" :percentage="ti.percent" style="width: 280px;"></el-progress>
                    </el-col>
                </el-row>

            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'progressDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false, examId: null,
                progressList: [], totalCount: {},
                customColors: [
                    {color: '#f56c6c', percentage: 20},
                    {color: '#e6a23c', percentage: 40},
                    {color: '#5cb87a', percentage: 60},
                    {color: '#1989fa', percentage: 80},
                    {color: '#6f7ad3', percentage: 100}
                ]
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            progress: {
                type: Object
            }
        },
        watch: {
            progress: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log('progress', n)
                    if(n){
                        this.examId = n.examId;
                        this.myTitle = n.name + "(" +n.id+") 阅卷总进度";
                        this.loadProgressList();
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadProgressList(){
                this.progressList = [];
                let search = { examId: this.examId, };
                let param = { controllerName: 'examTask', methodName: '/progress',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExamTopic", code, data);
                    if (code == 0) {
                        let zgSeqList = data.zgSeqList;
                        if(data.zgCountMap){
                            let z = data.zgCountMap;
                            this.totalCount = { myText: z.yy_cou+'/' +(z.w_cou+z.yy_cou),  wCou: z.w_cou, yyCount: z.yy_cou, percent: Math.round(z.yy_cou/(z.w_cou+z.yy_cou)*10000)/100 };
                        }
                        if(zgSeqList && zgSeqList.length > 0){
                            let progressList = []
                            for (let i=0; i<zgSeqList.length; i++){
                                let d = zgSeqList[i];
                                let examSeq = d.exam_seq;
                                if(examSeq.length > 7){
                                    examSeq = examSeq.substr(0,2) + "..." + examSeq.substr(examSeq.length-2,examSeq.length-1);
                                }
                                examSeq = examSeq + "(" + d.yy_cou + "/" + d.cou +")";
                                let o = {examSeq: examSeq, total: d.cou, yyCount: d.yy_cou, percent: Math.round(d.yy_cou/d.cou*1000)/10, teacherList: [] };
                                if(d.teacherList){
                                    let teacherList = [];
                                    for (let j=0; j<d.teacherList.length; j++){
                                        let myD = d.teacherList[j];
                                        let tch = {total: myD.cou, teacherName: myD.nick_name, yyCount: myD.yy_count, percent: Math.round(myD.yy_count/myD.cou*1000)/10,}
                                        teacherList.push(tch);
                                    }
                                    o.teacherList = teacherList;
                                }
                                progressList.push(o);
                            }
                            progressList = progressList.sort((a, b) => {
                              let seqA = a.examSeq, seqB = b.examSeq;
                              let arrA = seqA.split(","), arrB = seqB.split(",");
                              let vA = 0, vB = 0;
                              if(arrA.length > 1) vA = parseInt(arrA[0]); else vA = parseInt(seqA);
                              if(arrB.length > 1) vB = parseInt(arrB[0]); else vB = parseInt(seqB);
                              return vA - vB;
                            })
                            this.progressList = progressList
                        }
                    }

                }).catch((error) => {
                    console.log("error")
                });
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .progressDialog{
        height: 500px;
        overflow-x: auto;
    }


</style>


