<template>
    <div class="examTaskDialog">
        
        <el-dialog :title="myTitle" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row>
                <el-col :span="4">
                    <span>阅卷设置</span>
                </el-col>
                <el-col :span="6">
                    <el-checkbox v-model="baseSetting.showStudentInfo">显示学生信息</el-checkbox>
                </el-col>
                <el-col :span="6">
                    <el-checkbox v-model="baseSetting.showAllExam">允许查看原卷</el-checkbox>
                </el-col>
                <el-col :span="6">
                    <el-checkbox v-model="baseSetting.showKg">显示客观题分数</el-checkbox>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" style="color: red;margin-top: 15px;">
                    <span>四种分配方式选一种，分配设置完成后点击保存即可。</span>
                </el-col>
            </el-row>
            <el-divider content-position="left">
                <el-radio-group v-model="classMode">
                    <el-radio :label="2">题序号平均分配</el-radio>
                    <el-radio :label="0">行政班分配</el-radio>
                    <el-radio :label="1">教学班分配</el-radio>
                    <el-radio :label="3">混合分配</el-radio>
                </el-radio-group>
                <el-button v-if="isAllLose" type="text" size="mini" style="margin-left: 50px;" @click="outputLoseExcel(true)">缺考名单</el-button>
            </el-divider>
            <el-table v-loading="loading" v-if="classMode==0 || classMode==3" :data="hardClassData" style="width: 100%"  :height="tableHeight" align='center'>
                <el-table-column prop="hard_name" label="班级名称" width="240" align='center' ></el-table-column>
                <el-table-column prop="stu_count" label="班级学生" width="100"  align='center' ></el-table-column>
                <el-table-column prop="scan_count" label="考试人数" width="100"  align='center' >
                    <template slot-scope='scope'>
                        <el-popover placement="right" width="400"
                                    trigger="click">
                            <el-table :data="joinData" max-height="300">
                                <el-table-column  property="student_no" label="学号"></el-table-column>
                                <el-table-column  property="student_name" label="姓名"></el-table-column>
                            </el-table>
                            <!--                            <el-button slot="reference">click 激活</el-button>-->
                            <el-button  type="text" size="mini" @click="loadLoseExam(scope.row, false)" slot="reference">{{scope.row.scan_count}}</el-button>
                        </el-popover>

                    </template>
                </el-table-column>
                <el-table-column label="缺考名单"  align='center' width="100" >
                    <template slot-scope='scope'>
                        <el-popover placement="right" width="400"
                                trigger="click">
                            <el-table :data="loseData" max-height="300">
                                <el-table-column  property="student_no" label="学号"></el-table-column>
                                <el-table-column  property="student_name" label="姓名"></el-table-column>
                            </el-table>
<!--                            <el-button slot="reference">click 激活</el-button>-->
                            <el-button v-if="scope.row.stu_count !=scope.row.scan_count" type="text" size="mini" @click="loadLoseExam(scope.row, true)" slot="reference">{{scope.row.stu_count-scope.row.scan_count}}</el-button>
                        </el-popover>

                    </template>
                </el-table-column>
                <el-table-column prop="id" label="阅卷老师" min-width="180" align='center' >
                    <template slot-scope='scope'>
                        <el-tag v-if="scope.row.teachers" v-for="(tag, index) in scope.row.teachers"  :key="tag.id"  closable @close="tagClose(scope.row, index)">
                            {{tag.name}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" align='center' label="操作" min-width="340">
                    <template slot-scope='scope'>
                        <el-select v-model="scope.row.selTeacher" value-key="id" filterable placeholder="搜索选择阅卷教师后点击添加" remote clearable reserve-keyword
                                   :remote-method="loadTeacherList" style="width: 220px;margin-right: 8px;">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            <el-option v-for="item in teacherList" :key="item.id" :label="item.nick_name+ '(' +item.name + ')'" :value="item">
                            </el-option>
                        </el-select>
                        <el-button type="text" size="mini" @click='addTeacher(scope.row, 1)'>添加</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.teachers" @click='copyTeachers(scope.row.teachers, 1)'>复制</el-button>
                        <el-button type="text" size="mini" v-if="copyTeacher1" @click='parseTeachers(scope.row, 1)'>黏贴</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-table v-loading="loading" v-if="classMode==1" :data="teachingClassData" style="width: 100%"  :height="tableHeight" align='center'>
                <el-table-column prop="name" label="教学班名称" width="240" align='center' ></el-table-column>
                <el-table-column prop="student_count" label="教学班学生" width="100"  align='center' ></el-table-column>
                <el-table-column prop="kg_count" label="考试人数" width="100"  align='center' >
                    <template slot-scope='scope'>
                        <el-popover placement="right" width="400"
                                    trigger="click">
                            <el-table :data="joinTeachingData" max-height="300">
                                <el-table-column  property="hard_name" label="行政班"></el-table-column>
                                <el-table-column  property="student_no" label="学号"></el-table-column>
                                <el-table-column  property="student_name" label="姓名"></el-table-column>
                            </el-table>
                            <el-button type="text" size="mini" @click="loadLoseTeachingExam(scope.row, false)" slot="reference">{{scope.row.kg_count}}</el-button>
                        </el-popover>

                    </template>
                </el-table-column>
                <el-table-column label="缺考名单"  align='center' width="100" >
                    <template slot-scope='scope'>
                        <el-popover placement="right" width="400"
                                    trigger="click">
                            <el-table :data="loseTeachingData" max-height="300">
                                <el-table-column  property="hard_name" label="行政班"></el-table-column>
                                <el-table-column  property="student_no" label="学号"></el-table-column>
                                <el-table-column  property="student_name" label="姓名"></el-table-column>
                            </el-table>
                            <el-button v-if="scope.row.student_count !=scope.row.kg_count" type="text" size="mini" @click="loadLoseTeachingExam(scope.row, true)" slot="reference">{{scope.row.student_count-scope.row.kg_count}}</el-button>
                        </el-popover>

                    </template>
                </el-table-column>
                <el-table-column prop="id" label="阅卷老师" min-width="180" align='center' >
                    <template slot-scope='scope'>
                        <el-tag v-if="scope.row.teachers" v-for="(tag, index) in scope.row.teachers"  :key="tag.id"  closable @close="tagClose(scope.row, index)">
                            {{tag.name}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="operation" align='center' label="操作" min-width="180">
                    <template slot-scope='scope'>
                        <el-select v-model="scope.row.selTeacher" value-key="id" filterable placeholder="搜索选择阅卷教师后点击添加" remote clearable reserve-keyword
                                   :remote-method="loadTeacherList" style="width: 200px;margin-right: 8px;">
                            <i slot="prefix" class="el-input__icon el-icon-search"></i>
                            <el-option v-for="item in teacherList" :key="item.id" :label="item.nick_name+ '(' +item.name + ')'" :value="item">
                            </el-option>
                        </el-select>
                        <el-button type="text" size="mini" @click='addTeacher(scope.row, 1)'>添加</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.teachers" @click='copyTeachers(scope.row.teachers, 1)'>复制</el-button>
                        <el-button type="text" size="mini" v-if="copyTeacher1" @click='parseTeachers(scope.row, 1)'>黏贴</el-button>
                    </template>
                </el-table-column>
            </el-table>


            <el-divider content-position="left" v-if="classMode==2 || classMode==3">
                <span v-if="classMode==3">题序号平均分配,</span>
                教师:
                <el-select v-model="selTeacher2" value-key="id" filterable placeholder="请选择教师" remote clearable reserve-keyword
                           :remote-method="loadTeacherList" style="width: 120px;margin-right: 8px;">
                    <el-option v-for="item in teacherList" :key="item.id" :label="item.nick_name+ '(' +item.name + ')'" :value="item">
                    </el-option>
                </el-select>
                <el-button type="text" size="mini" @click='addBatchTeacher(2)'>批量添加</el-button>
                <el-button type="text" size="mini" @click='delBatchTeacher'>批量删除</el-button>
            </el-divider>
            <el-table v-loading="loading"  v-if="classMode==2 || classMode==3" :data="examSeqData" style="width: 100%"  :height="tableHeight" align='center'>
                <el-table-column prop="exam_seq" label="题序号"  min-width="80" align='center' ></el-table-column>
                <el-table-column prop="remark" label="备注"  min-width="80" align='center' ></el-table-column>
                <el-table-column prop="cou" label="总题块数" max-width="80" align='center' ></el-table-column>
                <el-table-column prop="id" label="阅卷老师" min-width="180" align='center' >
                    <template slot-scope='scope'>
                        <div v-if="scope.row.teachers && scope.row.teachers.length > 0">
                            <el-tag  v-for="(tag, index) in scope.row.teachers"  :key="tag.id"  closable  @close="tagClose(scope.row, index)">
                                {{tag.name}}
                            </el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="spec" label="特殊规则" max-width="80" align='center' >
                  <template slot-scope='{row}'>
                    <el-button type="text" size="mini" v-if="(row.teachers) && row.teachers.length > 0" @click='setTiRules(row)'>设置{{(('specRules' in row) && row.specRules!=null)?'(Y)':''}}</el-button>
                  </template>
                </el-table-column>
                <el-table-column prop="operation" align='center' label="操作" min-width="180">
                    <template slot-scope='scope'>
                        <el-select v-model="scope.row.selTeacher" value-key="id" filterable placeholder="请选择" remote clearable reserve-keyword
                                   :remote-method="loadTeacherList" style="width: 120px;margin-right: 8px;">
                            <el-option v-for="item in teacherList" :key="item.id" :label="item.nick_name+ '(' +item.name + ')'" :value="item">
                            </el-option>
                        </el-select>
                        <el-button type="text" size="mini" @click='addTeacher(scope.row, 2)'>添加</el-button>
                        <el-button type="text" size="mini" v-if="scope.row.teachers" @click='copyTeachers(scope.row.teachers, 2)'>复制</el-button>
                        <el-button type="text" size="mini" v-if="copyTeacher2" @click='parseTeachers(scope.row, 2)'>黏贴</el-button>
<!--                        <el-button type="text" size="mini" v-if="scope.row.teachers" @click='fenPeiOne(scope.row)'>分配</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" v-has-role="['teacher']" @click="submitSave">保 存</el-button>
            </div>
        </el-dialog>
      <tiSpecialDialog ref="tiSpecialDialog" :tiSpecial="selTi" @ok="tiRulesOK"></tiSpecialDialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'
import tiSpecialDialog from "./tiSpecialDialog";
import XLSX from "xlsx";

    export default {
        name: 'examTaskDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '任务分配', classMode: null,
                dialogVisible:false, loading: false, examInfo: null, selTeacher: null, selTeacher2: null, copyTeacher1: null, copyTeacher2: null,
                hardClassData: [], teachingClassData: [], examSeqData: [], teacherList: [], loseHardList: [], loseTeachingList: [],
                loseData: [], joinData: [], loseTeachingData: [], joinTeachingData: [], examId: null, isAllLose: false,
                baseSetting: { showStudentInfo: true, showAllExam: true, showKg: true},
                hardClassForm: { examId: null, teacherId: null }, selTi: null,
                examSeqForm: {  teacherId: null },

            }
        },
        components:{
          tiSpecialDialog
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
                userToken: state => state.user.token,
            }),
            tableHeight(){
                if(this.classMode != 3) return 500;
                return 250;
            }
        },
      	mounted() {

	    },
        props: {
            examTask: {
                type: Object
            }
        },
        watch: {
            examTask: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.loading = true;
                        this.examId = n.id;
                        let that = this;
                        this.loadExam(function () {
                            that.loadExamTask(n.id);
                        });
                        this.selTeacher = null;
                        this.selTeacher2 = null;
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadExam(callback){
                let param = { controllerName: 'akExam', methodName: '/get',  param: {id: this.examId}};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExam", code, data);
                    if (code == 0) {
                      this.myTitle = data.name + "(" +data.id+")";
                      this.examInfo = data;
                      if(data.rules != null){
                        let rules = JSON.parse(data.rules);
                        if(("baseSetting" in rules) && rules.baseSetting != null){
                          this.baseSetting.showStudentInfo = rules.baseSetting.showStudentInfo;
                          this.baseSetting.showAllExam = rules.baseSetting.showAllExam;
                          this.baseSetting.showKg = rules.baseSetting.showKg;
                        }
                      }
                    }
                    if(callback) callback();
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadTeacherList(query){
                console.log("query", query);
                if(query != ''){
                    let search = { licenceId: this.userInfo.licenceId, searchText: mUtils.searchText(query)};
                    let param = { controllerName: 'sclTeacher', methodName: '/list', pageNo: 0, pageSize: 5000, param: search};
                    return simpleApi.list(param).then(({result: {code, data}}) =>  {

                        if (data.code == 0) {
                            this.teacherList = data.result;
                        }
                    }).catch((error) => {
                        console.log("error")
                    });
                }else{

                    this.teacherList = [];
                }
            },
            outputLoseExcel(isLose){
                if(this.classMode == 0){
                    this.loadLoseExams(isLose);
                }
                if(this.classMode == 1){
                    this.loadLoseTeachingExams(isLose);
                }
            },
            loadLoseExam(row, isLose){
                this.loseData = [];
                this.joinData = [];
                let search = { examId: this.examId, hardClassId: row.id, lose: isLose?null:"Y" };
                let param = { controllerName: 'examTask', methodName: '/loseExamStudent',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadLoseExam", code, data);
                    if (code == 0) {
                        if(isLose){
                            this.loseData = data;
                        }else{
                            this.joinData = data;
                        }
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadLoseExams(isLose){

                let search = { examId: this.examId, hardClassIds: this.loseHardList, lose: isLose?null:"Y" };
                let param = { controllerName: 'examTask', methodName: '/loseExamStudent',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadLoseExam", code, data);

                    if (code == 0 && data && data.length > 0) {
                        let myData = XEUtils.sortBy(data, ['hard_name', 'student_no']);
                        let lst = [['行政班', '学号', '姓名']];
                        for (let i=0; i<myData.length; i++){
                            let d = myData[i];
                            lst.push([d.hard_name, d.student_no, d.student_name]);
                        }
                        this.outputXlsxFile(lst);
                    }

                }).catch((error) => {
                    console.log("error")
                });
            },
            loadLoseTeachingExam(row, isLose){
                this.loseTeachingData = [];
                this.joinTeachingData = [];
                let search = { examId: this.examId, teachingClassId: row.id, lose: isLose?null:"Y" };
                let param = { controllerName: 'examTask', methodName: '/loseTeachingExamStudent',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadLoseTeachingExam", code, data);
                    if (code == 0) {
                        if(isLose){
                            this.loseTeachingData = data;
                        }else{
                            this.joinTeachingData = data;
                        }
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadLoseTeachingExams(isLose){
                this.loseTeachingData = [];
                this.joinTeachingData = [];
                let search = { examId: this.examId, teachingClassIds: this.loseTeachingList, lose: isLose?null:"Y" };
                let param = { controllerName: 'examTask', methodName: '/loseTeachingExamStudent',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadLoseTeachingExam", code, data);
                    if (code == 0 && data && data.length > 0) {
                        let myData = XEUtils.sortBy(data, ['hard_name', 'student_no']);
                        let lst = [['行政班', '学号', '姓名']];
                        for (let i=0; i<myData.length; i++){
                            let d = myData[i];
                            lst.push([d.hard_name, d.student_no, d.student_name]);
                        }
                        this.outputXlsxFile(lst);
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            outputXlsxFile(data) {

                let sheetNames = ['缺考学生'];
                let sheetsList = {};
                let wb = XLSX.utils.book_new();
                sheetsList['缺考学生'] = XLSX.utils.aoa_to_sheet(data);
                // sheetsList['成绩单']["!cols"] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 },{ wpx: 100 } ,{ wpx: 100 },{ wpx: 100 }];

                console.log("sheetNames", sheetNames)
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;

                XLSX.writeFile(wb, "缺考学生.xlsx");
            },
            teacherChage(v){
                console.log("teacherChage", v);
            },
            tagClose(row, index){
                row.teachers.splice(index, 1);
                if("specRules" in row){
                  row.specRules = null
                }
            },
            setTiRules(row){
              console.log("setTiRules", row);
              this.selTi = Object.assign(row, {time:(new Date()).getMilliseconds()}) ;
              this.$refs.tiSpecialDialog.showDialog();
            },
            tiRulesOK(rule){
              console.log("tiRulesOK", rule);
              this.selTi["specRules"] = rule;
              this.$set(this, "examSeqData", this.examSeqData);
              console.log("tiRulesOK", this.examSeqData);
            },
            addBatchTeacher(v){
                let list = this.hardClassData;
                let myTeacher = this.selTeacher;
                console.log("addBatchTeacher", myTeacher);
                if(v == 2) {
                    list = this.examSeqData;
                    myTeacher = this.selTeacher2;
                }
                if((!myTeacher) || !myTeacher.id || myTeacher.id == null) return;
                for (let i=0; i<list.length; i++){
                    let d = list[i];
                    if(v == 2){
                        let teacherFind = XEUtils.find(d.teachers, item => item.id == myTeacher.id);
                        if(!teacherFind){
                            d.teachers.push({id: myTeacher.id, name: myTeacher.nick_name});
                        }
                    }else{
                        if(d.teachers.length > 0){
                            d.teachers[0].id = myTeacher.id;
                            d.teachers[0].name = myTeacher.nick_name;
                        }else{
                            d.teachers.push({id: myTeacher.id, name: myTeacher.nick_name});
                        }
                    }
                }
            },
            delBatchTeacher(){
                if(this.selTeacher2 != null && this.selTeacher2.id != null){
                    for (let i=0; i<this.examSeqData.length; i++){
                        let d = this.examSeqData[i];
                        let fidx = XEUtils.findIndexOf(d.teachers, item => item.id == this.selTeacher2.id);
                        if(fidx > -1){
                            d.teachers.splice(fidx, 1);
                        }
                    }
                }
            },
            addTeacher(row, v){
                console.log("addTeacher", row.selTeacher);
                if((!row.selTeacher) || !row.selTeacher.id || row.selTeacher.id == null) return;
                if(v == 1){
                    if(row.teachers!=null && row.teachers.length > 0){
                        row.teachers[0].id = row.selTeacher.id;
                        row.teachers[0].name = row.selTeacher.nick_name;
                    }else{
                        row["teachers"] = [{id: row.selTeacher.id, name: row.selTeacher.nick_name}];
                    }
                }else{
                    let teacherFind = XEUtils.find(row.teachers, item => item.id == row.selTeacher.id);
                    if(!teacherFind)
                        row.teachers.push({id: row.selTeacher.id, name: row.selTeacher.nick_name});
                }
            },
            copyTeachers(teachers, v){
                if(v ==1){
                    console.log("copyTeachers", teachers);
                    this.copyTeacher1 = JSON.parse(JSON.stringify(teachers));
                    this.$message({ type: 'success', message: '复制成功!'});
                }
                if(v ==2){
                    this.copyTeacher2 = JSON.parse(JSON.stringify(teachers));
                    this.$message({ type: 'success', message: '复制成功!'});
                }
            },
            parseTeachers(row, v){

                if(v ==1 && this.copyTeacher1 != null){
                    console.log("parseTeachers", row.teachers, this.copyTeacher1);
                    row.teachers = JSON.parse(JSON.stringify(this.copyTeacher1));
                    this.$message({ type: 'success', message: '黏贴成功!'});
                }
                if(v ==2 && this.copyTeacher2 != null){
                    if(row.teachers != null){
                        for (let i=0; i<this.copyTeacher2.length; i++){
                            let d = this.copyTeacher2[i];
                            let teacherFind = XEUtils.find(row.teachers, item => item.id == d.id);
                            if(!teacherFind) row.teachers.push(JSON.parse(JSON.stringify(this.copyTeacher2[i])));
                        }
                    }
                    this.$message({ type: 'success', message: '复制成功!'});
                }
            },
            loadExamTask(examId){
                let search = { createrId: this.userInfo.id, examId: examId, orderBy: 'id desc'};
                let param = { controllerName: 'examTask', methodName: '/byExamId',  param: search};
                this.loseHardList = []; this.loseTeachingList = []; this.isAllLose = false;
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("loadExamTask", data);
                    if (code == 0) {
                        this.hardClassData = [];
                        this.examSeqData = [];
                        this.teachingClassData = [];
                        let rules = null;
                        if(this.examInfo && this.examInfo.rules != null){
                            rules = JSON.parse(this.examInfo.rules);
                        }
                        if(rules && "classMode" in rules){
                            this.classMode = rules["classMode"];
                        }else{
                            this.classMode = 2;
                        }
                        if(rules && "teachingClassList" in rules){
                            this.loadTeachingClass(examId, rules.teachingClassList);
                        }
                        for (let i=0; i<data.hardClassList.length; i++){
                            let d = data.hardClassList[i];
                            if(rules && "hardClassList" in rules){
                                let hcFind = XEUtils.find(rules.hardClassList, item => item.hardClassId == d.id);
                                if(hcFind){
                                    d["teachers"] = [{id: hcFind.teacherId, name: hcFind.teacherName}];
                                }else{
                                    d["teachers"] = [];
                                }
                            }else{
                                d["teachers"] = [];
                            }
                            if(d.stu_count !=d.scan_count) {
                                this.loseHardList.push(d.id);
                                this.isAllLose = true;
                            }
                            d["selTeacher"] = null;
                            this.hardClassData.push(d);
                        }
                        let zgSeqList = data.zgSeqList
                        zgSeqList = zgSeqList.sort((a, b) => {
                          let seqA = a.exam_seq, seqB = b.exam_seq;
                          let arrA = seqA.split(","), arrB = seqB.split(",");
                          let vA = 0, vB = 0;
                          if(arrA.length > 1) vA = parseInt(arrA[0]); else vA = parseInt(seqA);
                          if(arrB.length > 1) vB = parseInt(arrB[0]); else vB = parseInt(seqB);
                          console.log("vA > vB", vA, vB,vA > vB )
                          return vA - vB;
                        })
                        for (let i=0; i<zgSeqList.length; i++){
                            let d = zgSeqList[i];
                            let teachers = [];
                            if(rules && "examSeqList" in rules){
                                for (let j=0; j<rules.examSeqList.length; j++){
                                    let es = rules.examSeqList[j];
                                    if(d.exam_seq == es.examSeq){
                                        teachers.push({id: es.teacherId, name: es.teacherName})
                                    }
                                }
                            }
                            if(rules && "specRulesList" in rules){
                              for (let j=0; j<rules.specRulesList.length; j++){
                                let es = rules.specRulesList[j];
                                if(d.exam_seq == es.examSeq){
                                  d["specRules"] =  es.specRules
                                }
                              }
                            }

                            d["teachers"] = teachers;
                            d["selTeacher"] = null;
                            this.examSeqData.push(d);
                        }
                      this.loading = false;
                    }
                }).catch((error) => {
                    console.log("error", error);
                  this.loading = false;
                });
            },
            loadTeachingClass(examId, teachingList){
                let teachingIds = [];
                teachingList.forEach((item, index) => {
                    teachingIds.push(item.id);
                });
                if(teachingIds.length == 0) return ;
                let search = { inTeachingClass: teachingIds, examId: examId,};
                let param = { controllerName: 'examTask', methodName: '/teachingClass',  param: search};
                return simpleApi.list(param).then(({result: {code, data}}) => {
                    console.log("loadTeachingClass", data);
                    if (code == 0) {
                        for (let i=0; i<data.length; i++){
                            let d = data[i];
                            let hcFind = XEUtils.find(teachingList, item => item.id == d.id);
                            if(hcFind){
                                d["teachers"] = hcFind.teachers;
                            }else{
                                d["teachers"] = [];
                            }
                            if(d.student_count !=d.kg_count) {
                                this.loseTeachingList.push(d.id);
                                this.isAllLose = true;
                            }
                            d["selTeacher"] = null;
                        }
                        this.teachingClassData = data;
                    }
                });
            },
            fenPeiOne(row){
              let paramData = {licenceId: this.userInfo.licenceId, examId: this.examId, examSeqTi: row};
              let param = { controllerName: 'examTask', methodName: '/saveTaskOne', param: paramData};

              simpleApi.post(param).then(({result: {code, data}}) =>  {
                if (code == 0) {
                  this.$alert('分配完成', '成功', {
                    confirmButtonText: '确定',
                  });
                }
              }).catch((error) => {
              });
            },
            submitSave(){
                let isHardClass = true, isTeachingClass = true, isExamSeq = true;
                let classMode = this.classMode;

                for (let i=0; i<this.hardClassData.length; i++){
                    let d = this.hardClassData[i];
                    if(d.teachers == null || d.teachers.length == 0){
                        isHardClass = false;
                        break;
                    }
                }
                for (let i=0; i<this.teachingClassData.length; i++){
                    let d = this.teachingClassData[i];
                    if(d.teachers == null || d.teachers.length == 0){
                        isTeachingClass = false;
                        break;
                    }
                }
                for (let i=0; i<this.examSeqData.length; i++){
                    let d = this.examSeqData[i];
                    if(d.teachers == null || d.teachers.length == 0){
                        isExamSeq = false;
                        break;
                    }
                }
                if((classMode == 2) && (!isExamSeq)){
                    this.$alert('按题块分配，需要全部分配完成', '错误', {
                        confirmButtonText: '确定',
                    });
                    return ;
                }
                if((classMode == 0 || classMode == 3) && (!isHardClass) && (!isExamSeq)){
                    this.$alert('按班级分配和按题块分配，至少有一种是需要全部分配完成', '错误', {
                        confirmButtonText: '确定',
                    });
                    return ;
                }
                if(classMode == 1 && (!isTeachingClass) && (!isExamSeq)){
                    this.$alert('按教学班分配和按题块分配，至少有一种是需要全部分配完成', '错误', {
                        confirmButtonText: '确定',
                    });
                    return ;
                }
                console.log("submitSave", this.hardClassData, this.examSeqData);
                this.loading = true;
                let hardClassList = [], examSeqList = [], teachingClassList = [], examSeqList2 = [], specRulesList = [], rules = {};
                for (let i=0; i<this.hardClassData.length; i++){
                    let d = this.hardClassData[i];
                    if(d.teachers != null && d.teachers.length > 0){
                        hardClassList.push({teacherId: d.teachers[0].id, teacherName: d.teachers[0].name, examId: this.examId, hardClassId: d.id , hardName: d.hard_name});
                    }
                }
                for (let i=0; i<this.teachingClassData.length; i++){
                    let d = this.teachingClassData[i];
                    if(d.teachers != null && d.teachers.length > 0){
                        teachingClassList.push({teacherId: d.teachers[0].id, teacherName: d.teachers[0].name, examId: this.examId, teachingClassId: d.id ,teachingName: d.name});
                    }
                }
                if((classMode == 2 || classMode == 3)){
                    for (let i=0; i<this.examSeqData.length; i++){
                        let d = this.examSeqData[i];
                        if(d.teachers != null && d.teachers.length > 0){
                            let e1 = {examId: this.examId, examSeq: d.exam_seq};
                            let teachers = d.teachers;
                            if("specRules" in d) {
                              let specRules = d.specRules;
                              specRulesList.push({examSeq: d.exam_seq, specRules: d.specRules})
                              if(specRules)
                              {
                                for (let k=0; k<specRules.list.length; k++){
                                  let sr = specRules.list[k]
                                  let tf = XEUtils.find(teachers, item => item.id == sr.id);
                                  if(tf){
                                    tf["valueMode"] = specRules.valueMode;
                                    tf["value"] = sr.value;
                                    console.log("specRules", tf)
                                  }
                                }
                              }
                            }
                            e1["teachers"] = teachers;
                            examSeqList.push(e1);
                            let limit = parseInt(d.cou / d.teachers.length);
                            for (let j=0; j<d.teachers.length; j++){
                                let t = d.teachers[j];
                                let e = {teacherId: t.id,  teacherName: t.name, examId: this.examId, examSeq: d.exam_seq};
                                if(j<d.teachers.length-1) e["limit"] = limit;
                                examSeqList2.push(e);
                            }
                        }
                    }
                }

                if(this.examInfo && this.examInfo.rules != null){
                    rules = JSON.parse(this.examInfo.rules);
                }
                rules["baseSetting"] = this.baseSetting;
                rules["hardClassList"] = (classMode == 0||classMode == 3)?hardClassList: null;
                rules["examSeqList"] = examSeqList2;
                rules["classMode"] = classMode;
                rules["specRulesList"] = specRulesList;
                if(classMode == 1 && this.teachingClassData.length > 0) rules["teachingClassList"] = this.teachingClassData;

                let paramData = {licenceId: this.userInfo.licenceId, examId: this.examId, rules: JSON.stringify(rules), hardClassList: hardClassList,
                    classMode: this.classMode, examSeqList: examSeqList, teachingClassList: teachingClassList};
                let param = { controllerName: 'examTask', methodName: '/saveTask', param: paramData};

                simpleApi.post(param).then(({result: {code, data}}) =>  {
                    console.log('submitSave', code);
                    if (code == 0) {
                        this.dialogVisible = false;
                        this.$emit('ok');
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .examTaskDialog{

    }


</style>


