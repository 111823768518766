<template>
    <div class="topicSpecialDialog">
        
        <el-dialog :title="myTitle" width="500px"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row style="margin-bottom: 20px;">
                <el-col :span="16">
                  <el-radio-group v-model="valueMode">
                    <el-radio :label="1">按百分比</el-radio>
                    <el-radio :label="0">按数量</el-radio>
                  </el-radio-group>
                </el-col>
                <el-col :span="8">
                  <span v-if="valueMode==0">总数量：{{calcValue}}</span>
                  <span v-else>总比例：{{calcValue}}%</span>
                </el-col>
            </el-row>

            <el-table v-loading="loading" :data="tableData" style="width: 100%" height="400" align='center'>

              <el-table-column prop="name" min-width="80" label="教师" align='center' ></el-table-column>
              <el-table-column prop="value" label="百分比"  align='center' v-if="valueMode==1">
                  <template slot-scope='{row}'>
                      <el-input placeholder="请输入内容" v-model="row.value" @input="inputChange">
                        <template slot="append">%</template>
                      </el-input>
                    </template>
                </el-table-column>
              <el-table-column prop="value" label="题数"  align='center' v-if="valueMode==0" >
                <template slot-scope='{row}'>
                  <el-input placeholder="请输入内容" v-model="row.value" @input="inputChange">
                    <template slot="append">道</template>
                  </el-input>
                </template>
              </el-table-column>

            </el-table>

            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import * as simpleApi from "request/simple";
import XEUtils from 'xe-utils'
import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'topicSpecialDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '特殊规则', valueMode: 1, tiCount: 0,
                dialogVisible:false, loading: false, batchScore: null, calcValue: 0,
                tableData: [], 
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            tiSpecial: {
                type: Object
            }
        },
        watch: {
            tiSpecial: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                      this.tiCount = n.cou;
                      this.myTitle = "" + n.exam_seq + "，题数：" + this.tiCount;
                      if(("specRules" in n) && n.specRules!=null){
                        let srList = n.specRules.list;
                        for (let i=0; i<n.teachers.length; i++){
                          let tf = XEUtils.find(srList, item => item.id == n.teachers[i].id);
                          if(!tf){
                            srList.push({id: n.teachers[i].id, name: n.teachers[i].name, value: 0});
                          }
                        }
                        this.tableData = srList;
                        this.valueMode = n.specRules.valueMode;
                      }else{
                        let teachers = [];
                        for (let i=0; i<n.teachers.length; i++){
                          teachers.push({id: n.teachers[i].id, name: n.teachers[i].name, value: 0});
                        }
                        this.tableData = teachers;
                        this.valueMode = 1;
                      }
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            inputChange(){
              let c = 0;
              for (let i=0; i<this.tableData.length; i++){
                let d = this.tableData[i];
                c = c + parseFloat(d.value);
              }
              this.calcValue = c;
            },
            submitForm(){
                let c = 0;
                for (let i=0; i<this.tableData.length; i++){
                    let d = this.tableData[i];
                    c = c + parseFloat(d.value);
                }
                if(this.valueMode == 0){
                  if(c != this.tiCount){
                    this.$alert('分配题数与总题数不一致，请重新调整', '提醒', {confirmButtonText: '确定',});
                    return ;
                  }
                }
                if(this.valueMode == 1){
                  if(c != 100){
                    this.$alert('分配比例必须是100%，请重新调整', '提醒', {confirmButtonText: '确定',});
                    return ;
                  }
                }
                this.$emit('ok', {valueMode: this.valueMode, list: this.tableData});
                this.dialogVisible = false;
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .topicSpecialDialog{

    }


</style>


