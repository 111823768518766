<template>
    <div class="newExamList">
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" @submit.native.prevent class="demo-form-inline search-form">
                <el-form-item label="学年学期" >
                    <el-select v-model="searchForm.semester" filterable placeholder="请选择" style="width: 100%">
                        <el-option :key="null" label="全部" :value="null"></el-option>
                        <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="考试状态">
                    <el-select v-model="searchForm.state" filterable placeholder="请选择">
                        <el-option :key="null" label="全部" :value="null"></el-option>
                        <el-option :key="0" label="阅卷中" :value="0"></el-option>
                        <el-option :key="2" label="已结束" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="名称"  @keyup.enter.native='loadNewExamList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" size ="mini" icon="search" @click='loadNewExamList'>查询</el-button>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" size ="mini" icon="search" v-has-role="['teacher']" @click='openNewExam(null)'>新建考试</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table_container" v-loading="loading">
            <el-card class="box-card" v-for="(item, index) in tableData">
                <div slot="header" class="clearfix" style="text-align: left; padding: 0px 15px">
                    <span :style="{backgroundColor: getExamTypeColor(item.category), padding: '5px', color: 'white', fontSize: '14px'}">{{getExamType(item.category)}}</span>
                    <span style="font-size: 14px;margin-left: 10px;color: #4D4D4D;">{{item.semester}}</span>
                    <span style="font-size: 16px;font-weight: bold; margin-left: 10px;">{{item.name}}(考试号：{{item.id}})</span>
                    <el-button class="card-right-btn" type="text"  v-has-role="['teacher']" v-if="item.scan_count < 1 && item.state< 2" @click="onDeleteNewExam(item)">删除</el-button>
                    <el-button class="card-right-btn" type="text" v-has-role="['teacher']" v-if="item.state< 2" @click="openNewExam(item)">编辑</el-button>
                    <el-button class="card-right-btn" type="text" v-if="item.sheet_id != null" @click="downloadExam(item)" >下载答题卡</el-button>
                    <el-button class="card-right-btn" type="text" v-if="item.activeStep > 4" @click="openProgress(item)" >阅卷进度</el-button>
                    <el-button class="card-right-btn" type="text" v-if="item.activeStep > 4" @click="openQuality(item)" >阅卷质量</el-button>
                    <el-button class="card-right-btn" type="text" v-if="item.activeStep > 4" @click='openAppend(item)'>补录数据</el-button>
                </div>
                <div class="text item">
                    <el-steps :active="item.activeStep" align-center>
                        <el-step title="绑卡" >
                            <div slot="description" class="stepContain">
                                <div v-if="item.exam_sheet == null">
                                    您尚未绑卡<br>
                                    <el-button type="primary" size ="mini" icon="search" v-has-role="['teacher']" @click='openBindExamDialog(item)'>点击绑卡</el-button>
                                </div>
                                <div v-else >
                                    {{item.exam_sheet}}<br>
<!--                                    item.activeStep <=3 &&-->
                                    <el-button type="primary" size ="mini" v-has-role="['teacher']" v-if="item.state< 2" @click='openBindExamDialog(item)'>重新绑卡</el-button>
                                </div>
                            </div>
                        </el-step>
                        <el-step title="设置答案">
                            <div slot="description" v-if="item.activeStep > 1" class="stepContain">

                                <div v-if="item.topic_score > 0">
                                    您已设置了答案及分数<br>
                                    <el-button type="primary" size ="mini" v-if="item.state< 2" @click='openNewExamTopicDialog(item)'>点击查看</el-button>
                                </div>
                                <div v-else >
                                    您尚未配置答案及分数<br>
                                    <el-button type="primary" size ="mini" icon="search" v-has-role="['teacher']" @click='openNewExamTopicDialog(item)'>点击设置</el-button>
                                </div>

                            </div>
                        </el-step>
                        <el-step title="扫描">
                            <div slot="description" v-if="item.activeStep > 2" class="stepContain">

                                <div v-if="item.scan_count > 0">
                                    您已识别了{{item.scan_count}}份试卷<br>
                                    <el-button type="primary" size ="mini" v-if="item.state< 2" v-has-role="['teacher']" @click='openTeachingClass(item)'>设置教学班</el-button>
                                    <br>
                                    <span v-if="item.teachingClassList!=null" >设置了{{item.teachingClassList.length}}个教学班</span>
                                    <br>

                                    <el-button v-if="item.teachingClassList!=null" type="success" size ="mini"  @click="outputMissingStudentList(item.id, item.teachingClassList)">缺考名单</el-button>

                                </div>
                                <div v-else >
                                    您尚未扫描试卷
                                </div>

                            </div>
                        </el-step>
                        <el-step title="任务分配">
                            <div slot="description" v-if="item.activeStep > 3" class="stepContain">
                                <div v-if="item.isTask">
                                    您已分配了任务<br>
                                    <el-button type="primary" size ="mini" icon="search" v-if="item.state< 2" @click='openExamTaskDialog(item)'>点击查看</el-button>
                                </div>
                                <div v-else >
                                    您尚未配置任务<br>
                                    <el-button type="primary" size ="mini" icon="search" v-if="item.state< 2" v-has-role="['teacher']" @click='openExamTaskDialog(item)'>点击分配</el-button>
                                </div>
                            </div>
                        </el-step>
                        <el-step title="阅卷">
                            <div slot="description" v-if="item.activeStep > 4" class="stepContain">
                                总共{{item.kg_count}}份试卷，{{item.zg_count}}道主观题
                                <span v-if="item.zg_yy_count > 0">
                                    ，已阅{{item.zg_yy_count}}道主观题
                                </span>
                                <br>
                                <el-button type="primary" size ="mini" icon="search" v-if="item.state< 2" v-has-role="['teacher']" @click='openScoring(item)'>点击阅卷</el-button>
                            </div>
                        </el-step>
                        <el-step title="阅卷结束">
                            <div slot="description" v-if="item.activeStep > 5" class="stepContain">
                                阅卷结束后可查询成绩及查看报表<br>
                                <el-button type="danger" size ="mini" icon="search" v-if="item.state< 2" v-has-role="['teacher']" @click='overExam(item, 2)'>点击结束阅卷</el-button>
                                <el-button type="success" size ="mini" icon="search" v-if="item.state>1 && item.state<99" v-has-role="['teacher']" @click='overExam(item, 0)'>重启阅卷</el-button>
                            </div>
                          <div slot="description" v-else-if="item.kg_count > 0 && item.zg_count < 1" class="stepContain">
                            阅卷结束后可查询成绩及查看报表<br>
                            <el-button type="danger" size ="mini" icon="search" v-if="item.state< 2" v-has-role="['teacher']" @click='overExam(item, 2)'>点击结束阅卷</el-button>
                            <el-button type="success" size ="mini" icon="search" v-if="item.state>1 && item.state<99" v-has-role="['teacher']" @click='overExam(item, 0)'>重启阅卷</el-button>
                          </div>
                        </el-step>
                    </el-steps>
                </div>
            </el-card>

        </div>
        <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
        <newExamDialog ref="newExamDialog" :newExam="selNewExam" @ok="loadNewExamList"></newExamDialog>
        <newExamTopicDialog ref="newExamTopicDialog" :newExamTopic="selTopic" @ok="loadNewExamList"></newExamTopicDialog>
        <bindExamDialog ref="bindExamDialog" :bindExam="selBindExam" @ok="loadNewExamList"></bindExamDialog>
        <examTaskDialog ref="examTaskDialog" :examTask="selExamTask" @ok="loadNewExamList"></examTaskDialog>
        <teachingClassDialog ref="teachingClassDialog" :teachingClass="selTeachingClass" @ok="loadNewExamList"></teachingClassDialog>
        <progressDialog ref="progressDialog" :progress="selProgress" ></progressDialog>
        <appendDialog ref="appendDialog" :append="selAppend" ></appendDialog>
      <qualityDialog ref="qualityDialog" :quality="selQuality"></qualityDialog>
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import bindExamDialog from "./bindExamDialog";
    import newExamDialog from "./newExamDialog";
    import newExamTopicDialog from "./newExamTopicDialog";
    import examTaskDialog from "./examTaskDialog";
    import teachingClassDialog from "./teachingClassDialog";
    import progressDialog from "./progressDialog";
    import appendDialog from "./appendDialog";
    import qualityDialog from "./qualityDialog";
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import scoring from "../scoring/scoring";
    import XLSX from "xlsx";
    import {getDomain} from "@/utils/mUtils";

    export default {
        name: 'newExamList',
        data(){
            return {
                tableData: [], tableHeight:0, acadyearTermList: [],
                loading:true, isShow:false, jsonLoading: false,
                jsonData: [],
                selNewExam: null, selBindExam: null, selTopic: null, selExamTask: null, selTeachingClass: null, selProgress: null, selAppend: null, selQuality: null,
                searchForm:{ pageNo: 1, pageSize: 20, totalCount: 0, semester: null, searchText: null, state: null},

            }
        },
        components:{
            newExamDialog, bindExamDialog, newExamTopicDialog, examTaskDialog, teachingClassDialog, progressDialog, appendDialog, qualityDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadAcadyearTermList();
            this.loadNewExamList();
            console.log('userInfo', this.userInfo)
	   },
        methods: {

            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+2); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            loadNewExamList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), semester: mUtils.searchText(this.searchForm.semester), licenceId: this.userInfo.licenceId,
                    state: XEUtils.toNumber(this.searchForm.state),  orderBy: 'e.id desc'};
                if(this.userInfo.role == 'teacher') search['teacherId'] = this.userInfo.id;
                if(this.searchForm.state == null) search.state = null;
                let param = { controllerName: 'akExam', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {

                    if (data.code == 0) {

                        let tableData = data.result;
                        for (let i=0; i<tableData.length; i++){
                            let d = tableData[i];
                            let activeStep = 1;
                            if(d.exam_sheet != null) activeStep =  2;
                            if(d.topic_score > 0) activeStep =  3;
                            if(d.scan_count > 0) activeStep =  4;
                            if(d.rules != null) {
                                let rules = JSON.parse(d.rules);
                                // if("examSeqList" in rules) activeStep =  5;
                                d["isTask"] = false;
                                if(("hardClassList" in rules) && rules.hardClassList!=null && rules.hardClassList.length > 0) {d["isTask"] = true; activeStep =  5;}
                                if(("examSeqList" in rules) && rules.examSeqList!=null && rules.examSeqList.length > 0) { d["isTask"] = true; activeStep =  5; }
                                if(("teachingClassList" in rules) && rules.teachingClassList!= null && rules.teachingClassList.length > 0){
                                    d["teachingClassList"] = rules.teachingClassList;
                                    activeStep =  5;
                                    d["isTask"] = true;
                                }
                            }
                            if(d.zg_yy_count > 0) activeStep =  6;
                            d.activeStep = activeStep;
                        }
                        console.log(tableData)
                        this.tableData = tableData;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;

                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error", error)
                });
            },

            outputMissingStudentList(examId, teachingList){
                let myList = [];
                teachingList.forEach((item, index) => {
                    myList.push(item.id);
                })
                if(myList.length == 0) return ;
                this.jsonLoading = true;
                let search = { examId: examId, teachingClassIds: myList};
                let param = { controllerName: 'examTask', methodName: '/teachingMissingStudent', pageNo: 0, pageSize: 5000, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (code == 0 && data && data.length > 0) {
                        let lst = [['行政班', '学号', '姓名']];
                        for (let i=0; i<data.length; i++){
                            let d = data[i];
                            lst.push([d.hard_name, d.student_no, d.student_name]);
                        }
                        this.outputXlsxFile(lst);
                    }
                    this.jsonLoading = false;
                }).catch((error) => {
                    console.log("error", error);
                    this.jsonLoading = false;
                });
            },
            outputXlsxFile(data) {

                let sheetNames = ['缺考学生'];
                let sheetsList = {};
                let wb = XLSX.utils.book_new();
                sheetsList['缺考学生'] = XLSX.utils.aoa_to_sheet(data);
                // sheetsList['成绩单']["!cols"] = [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 },{ wpx: 100 } ,{ wpx: 100 },{ wpx: 100 }];

                console.log("sheetNames", sheetNames)
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;

                XLSX.writeFile(wb, "缺考学生.xlsx");
            },
            getExamType(v){
                if(v == 0) return "期中";
                if(v == 1) return "期末";
                if(v == 2) return "补考";
                if(v == 3) return "测验";
                if(v == 4) return "其他";
                return v;
            },
            getExamTypeColor(v){
                if(v == 0) return "#b074ef";
                if(v == 1) return "#6474ee";
                if(v == 2) return "#ef6e9b";
                if(v == 3) return "#66aeef";
                if(v == 4) return "#f19870";
                return '#000';
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadNewExamList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadNewExamList()
            },
            onDeleteNewExam(row){
                let id = row.id
                let that = this;
                this.$confirm('此操作将永久删除该试卷, 是否继续?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'akExam', methodName: '/delete', param: { id: id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadNewExamList()
                        }
                    });
                }).catch(() => {

                });
            },
            downloadExam(row){

                window.location.href = getDomain()+ '/scantronjava/scantron/api/list/download?id='+row.sheet_id;
            },
            openNewExam(row){
                if(row != null){
                    this.selNewExam = Object.assign(row, {time: (new Date()).getMilliseconds()});
                }else{
                    this.selNewExam = { exam_time: new Date(), time: (new Date()).getMilliseconds()};
                }
                this.$refs.newExamDialog.showDialog();
            },
            openBindExamDialog(row){
                if(row != null){
                    this.selBindExam = Object.assign(row,{ time: (new Date()).getMilliseconds()});
                }
                this.$refs.bindExamDialog.showDialog();
            },
            openProgress(row){
                this.selProgress = Object.assign({examId: row.id, time: (new Date()).getMilliseconds()}, row);
                this.$refs.progressDialog.showDialog();
            },
            openQuality(row){
              this.selQuality = Object.assign({examId: row.id, time: (new Date()).getMilliseconds()}, row);
              this.$refs.qualityDialog.showDialog();
            },
            openNewExamTopicDialog(row){

                if(row != null){
                    let selTopic = Object.assign({time: new Date().getMilliseconds(), randomNo: Math.round(Math.random()*50000) }, row);
                    this.$set(this, "selTopic", selTopic);
                    console.log("openNewExamTopicDialog", this.selTopic);
                }
                this.$refs.newExamTopicDialog.showDialog();
            },
            openExamTaskDialog(row){
                if(row != null){
                    this.selExamTask = Object.assign({time: (new Date()).getMilliseconds()}, row );
                }
                this.$refs.examTaskDialog.showDialog();
            },
            openTeachingClass(row){
                this.selTeachingClass = Object.assign(row, {time: (new Date()).getMilliseconds()});
                this.$refs.teachingClassDialog.loadTeachingClassList(this.selTeachingClass.id);
                this.$refs.teachingClassDialog.showDialog();
            },
            openScoring(item){
                let routeData = this.$router.resolve({name: "scoring", query: {e: item.id, }});
                window.open(routeData.href, '_blank');
            },
            overExam(item, state){
                let id = item.id;
                let that = this;
                let s = "是否结束此阅卷？";
                if(state < 2){
                    s = "是否重启阅卷？";
                }
                this.$confirm(s, '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'akExam', methodName: '/overExam', param: { id: id, state: state }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            let sMsg = '结束阅卷成功!';
                            if(state < 2){
                                sMsg = "重启阅卷成功!";
                            }
                            that.$message({ type: 'success', message: sMsg});
                            that.loadNewExamList()
                        }

                    });
                }).catch((err) => {

                });
            },
            openAppend(row){
                this.selAppend = Object.assign(row, {time: (new Date()).getMilliseconds()});
                this.$refs.appendDialog.showDialog();
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .newExamList{

        .search_container{

        }
        .box-card {
            width: 100%;

        }
        .card-right-btn{
            float: right; 
            padding: 3px 0; 
            margin-right: 5px;
            font-size: 20px;
        }
        .clearfix:before,
        .clearfix:after {
            display: table;
            content: "";
        }
        .clearfix:after {
            clear: both
        }
        .stepContain{
            color: black;
        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
           width: 600px !important;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
    }

</style>


