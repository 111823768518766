<template>
    <div class="teachingClassDialog">
        <el-dialog :title="myTitle" width="60%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
                <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">
                    <el-form-item label="学年学期"  >
                        <el-select v-model="searchForm.acadyearTerm" filterable placeholder="请选择">
                            <el-option key="" label="全部" value=""></el-option>
                            <el-option v-for="item in acadyearTermList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="查找">
                        <el-input v-model="searchForm.searchText" placeholder="名称"  @keyup.enter.native='loadTeachingClassList'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="info" size ="mini" icon="search" @click='loadTeachingClassList'>查询</el-button>
                    </el-form-item>
                </el-form>
            <el-table v-loading="loading" :data="tableData" height="350" style="width: 100%" align='center'>
                <el-table-column prop="name" label="名称"  width="150" align='center' ></el-table-column>
                <el-table-column prop="acadyear_term" label="学年学期" width="150" align='center' ></el-table-column>
                <el-table-column prop="cource_name" label="课程名称" align='center' ></el-table-column>
                <el-table-column prop="hard_class_count" label="行政班数" align='center' ></el-table-column>
              <el-table-column prop="student_count" label="学生人数" align='center' ></el-table-column>
              <el-table-column prop="scan_student_count" label="扫描人数" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作" min-width="350">
                    <template slot-scope='scope'>
                        <el-button icon='edit' type="primary" size="mini" @click='selectTeaching(scope.row)'>选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>

            <div style="margin-top: 20px; text-align: left;">
                <el-tag v-for="(tag, index) in selectList" :key="tag.id" closable  @close="handleClose(index)"
                > {{tag.name}}
                </el-tag>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'teachingClassDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false, examId: null,  rules: null,
                tableData: [], acadyearTermList: [], selectList: [],
                searchForm: { pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, licenceId: null, teacherId: null, schoolId: null, acadyearTerm: null, },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
            this.loadTeachingClassList();
            this.loadAcadyearTermList();
	    },
        props: {
            teachingClass: {
                type: Object
            }
        },
        watch: {
            teachingClass: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.examId = n.id;
                        this.myTitle = n.name + "(" +n.id+")";
                        this.searchForm.searchText = null;
                        let rules = n.rules;
                        if(rules != null) {
                            let myRules = JSON.parse(rules);
                            this.rules = myRules;
                            if("teachingClassList" in myRules)
                                this.selectList = myRules.teachingClassList;
                            else
                                this.selectList = [];
                        }
                    }else{
                        this.selectList = [];
                        this.rules = null;
                    }
                }
            },
        },
        methods: {
            loadTeachingClassList(exam_id){
                console.log("loadTeachingClassList")
                console.log("loadTeachingClassList"+exam_id)
                if (exam_id != null)
                {
                  this.examId = exam_id;
                }
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    teacherId: this.userInfo.id,
                    examId: this.examId,
                    schoolId: mUtils.searchText(this.searchForm.schoolId), courseId: mUtils.searchText(this.searchForm.courseId),
                    acadyearTerm: mUtils.searchText(this.searchForm.acadyearTerm)};
                let param = { controllerName: 'examTeachingClass', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code === 0) {
                        this.tableData = data.result;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            loadAcadyearTermList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-3); i<(year+1); i++){
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第一学期');
                    this.acadyearTermList.unshift((i-1) + '-' + i + '第二学期');
                }
            },
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            handleClose(index){
                this.selectList.splice(index, 1);
            },
            selectTeaching(row){
                this.selectList.push({id: row.id, name: row.name, })
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadTeachingClassList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadTeachingClassList()
            },
            submitForm(){
                let that = this;
                if(this.rules == null) this.rules = {teachingClassList: null};
                this.rules["teachingClassList"] = this.selectList;
                let param = { controllerName: 'akExam', methodName: '/update', param: {id: this.examId, rules: JSON.stringify(this.rules)}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                    console.log('teachingClass', code);
                    if (code == 0) {
                        this.dialogVisible = false;
                        that.$emit('ok');
                    }
                });

            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .teachingClassDialog{

    }


</style>


